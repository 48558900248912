import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from '../../Utils/Config';
import { useNavigate } from 'react-router-dom';
import pro from "../../assets/images/profile.png";

const SDashboard = () => {
    const [messages, setMessages] = useState([]);
    const [buttonStates, setButtonStates] = useState({});
    const [buttonStatesgeneral, setButtonStatesgeneral] = useState({});
    const [secondUserActive, setSecondUserActive] = useState(true);
    const [buttonStatesGeneral, setButtonStatesGeneral] = useState({});
    const navigate = useNavigate();
    
    const [generalmessages, setgeneralMessages] = useState([]);

    // Redirect if no token or based on other conditions
    useEffect(() => {
        const speakerToken = localStorage.getItem('speakerAuthToken');
        if (!speakerToken) {
            navigate('/speakerlogin');
        }
        // Removed the navigate('/seconduserd') as it might interfere with the display of messages
    }, [navigate]);

    // Fetch speaker status
    useEffect(() => {
        const fetchSpeakerStatus = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/speaker/status`);
                const speakerStatus = response.data.status;
                setSecondUserActive(speakerStatus === 'active');
            } catch (error) {
                
            }
        };

        fetchSpeakerStatus();
    }, []);

    // Fetch messages
    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/speaker/messages/`);
                const allMessages = response.data || [];
                allMessages.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setMessages(allMessages);

                const storedButtonStates = localStorage.getItem("buttonStates");
                const initialButtonStates = storedButtonStates ? JSON.parse(storedButtonStates) : {};
                setButtonStates(initialButtonStates);
            } catch (error) {
                
            }
        };

        fetchMessages();
        const intervalId = setInterval(fetchMessages, 10000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchGeneralMessages = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/speaker/general_messages/`);
                const allMessages = response.data || [];
                allMessages.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setgeneralMessages(allMessages);

                const storedButtonStatesGeneral = localStorage.getItem("buttonStatesGeneral");
                const initialButtonStatesGeneral = storedButtonStatesGeneral ? JSON.parse(storedButtonStatesGeneral) : {};
                setButtonStatesGeneral(initialButtonStatesGeneral);
            } catch (error) {
              
            }
        };

        fetchGeneralMessages();
        const intervalId = setInterval(fetchGeneralMessages, 10000);
        return () => clearInterval(intervalId);
    }, []);

    // Toggle message answer state
    const toggleAnswer = async (messageId) => {
        try {
            const endpoint = `${BASE_URL}/speaker/messages/${messageId}/update/`;
            const response = await axios.put(endpoint);
            const updatedMessage = response.data;

            setButtonStates(prevState => {
                const updatedState = {
                    ...prevState,
                    [messageId]: updatedMessage.answered
                };
                localStorage.setItem("buttonStates", JSON.stringify(updatedState));
                return updatedState;
            });
        } catch (error) {
           
        }
    };

    const toggleAnswer2 = async (messageId) => {
        try {
            const endpoint = `${BASE_URL}/speaker/general_messages/${messageId}/update/`;
            const response = await axios.put(endpoint);
            const updatedMessage = response.data;

            setButtonStatesGeneral(prevState => {
                const updatedState = {
                    ...prevState,
                    [messageId]: updatedMessage.answered
                };
                localStorage.setItem("buttonStatesGeneral", JSON.stringify(updatedState));
                return updatedState;
            });
        } catch (error) {
           
        }
    };
    // Logout function
    const handleLogout = () => {
        
        localStorage.removeItem('speakerAuthToken');
        localStorage.removeItem('logoutTime');
        navigate('/speakerlogin');
    };

    // Format date and time
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${hours}:${minutes} ${period}`;
    };

    return (
        <div className="w-full h-full">
            <div className="flex justify-between p-12">
                <div></div>
                <div
                    className="flex justify-center items-center px-5 py-2 rounded-[105px] border border-[#00549A] hover:border-[#1AB78D] hover:bg-[#1AB78D] hover:text-white"
                    onClick={handleLogout}
                >
                    <p className="text-[14px] not-italic font-bold leading-[normal]">Logout</p>
                </div>
            </div>
            <hr />
            <div className="p-12">
                <p className="text-[24px] font-semibold">User's Messages</p>
                <div className="pt-6">
                    {/* Render Messages */}
                    {messages.map((message) => (
                        <div key={message.id} className="pt-6">
                            <div className="border rounded-[10px] bg-gray-50 w-full p-8">
                                <div className="flex gap-4 text-[20px] font-semibold">
                                    <p>{message.event_name} </p>
                                    <p className="text-[#1AB78D]">{message.forum_name}</p>
                                </div>
                                <p className="text-[18px] pt-4">{message.content}</p>
                                <div className="flex justify-between">
                                    <div className="flex gap-6 pt-4">
                                        <img
                                            src={message.author_profile?.image ? BASE_URL + message.author_profile.image : pro}
                                            alt="Author"
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <p className="pt-2">{message.author_name || 'Unknown'}</p>
                                        <p className="pt-2">{formatDate(message.timestamp)} - {formatTime(message.timestamp)}</p>
                                    </div>
                                    <div className="">
                                    <button
                                        onClick={() => toggleAnswer(message.id)}
                                        className={`p-3 rounded-[8px] w-[100%] text-white ${buttonStates[message.id] ? 'bg-[#00549A]' : 'bg-[#1AB78D]'}`}
                                    >
                                        {buttonStates[message.id] ? 'Answered' : 'Answer'}
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {generalmessages.map((message) => (
                        <div key={message.id} className="pt-6">
                            <div className="border rounded-[10px] bg-gray-50 w-full p-8">
                                <div className="flex gap-4 text-[20px] font-semibold">
                                    <p>{message.event_name} </p>
                                    
                                </div>
                                <p className="text-[18px] pt-4">{message.content}</p>
                                <div className="flex justify-between">
                                    <div className="flex gap-6 pt-4">
                                        <img
                                            src={message.author_profile?.image ? BASE_URL + message.author_profile.image : pro}
                                            alt="Author"
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <p className="pt-2">{message.author_name || 'Unknown'}</p>
                                        <p className="pt-2">{formatDate(message.timestamp)} - {formatTime(message.timestamp)}</p>
                                    </div>
                                    <div className="">
                                    <button
                                        onClick={() => toggleAnswer2(message.id)}
                                        className={`p-3 rounded-[8px] text-white w-[100%] ${buttonStatesGeneral[message.id] ? 'bg-[#00549A]' : 'bg-[#1AB78D]'}`}
                                    >
                                        {buttonStatesGeneral[message.id] ? 'Answered' : 'Answer'}
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SDashboard;
