import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import { Link } from 'react-router-dom';
import Page1 from '../../../assets/images/BoardofDirectors.jpg';
import Logo23 from '../../../assets/images/Vector-2.svg';

const Committees = () => {
    const [boardMembers, setBoardMembers] = useState([]);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchBoardMembers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/commboardmembers/`);
                setBoardMembers(response.data);
            } catch (error) {
               
            }
        };

        fetchBoardMembers();
    }, []);


    const getQualification = (qualification) => {
        return qualification.split(' – ')[0];
    };
    const boardMembers1 = [
        { borderColor: '#F75FDE', bgColor: '#feecfb' },
        { borderColor: '#2DCA72', bgColor: '#EAFBF1' },
        { borderColor: '#00549A', bgColor: '#F0FAFF' },
        { borderColor: '#FF7D52', bgColor: '#FFF2EE' },
        { borderColor: '#9452FF', bgColor: '#EEF0FF' },
        { borderColor: '#B1B1B1', bgColor: '#F2F2F2' }
    ];

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-4">
                    <div className="bg-white rounded-[30px] pall-20">
             
                        <div className=" about-boards">
                            <motion.div
                                className="md:text-start pt-4 width50 mob-with-100 "
                                initial={{ opacity: 0, y: 50 }}
                                animate={controls}
                                transition={{ duration: 0.6 }}
                                ref={ref}
                            >

                                <p className="text-[#222] pt-14 m-pt-14 pt-m-0 text-[34px] not-italic font-bold leading-[normal]">Committee</p>
                                <p className="text-[#58585A] pt-8 pt-m-14 text-[19px] not-italic font-normal leading-[28px] about-text-padding">
                                The committees of the Federation of Clinical Pharmacists in India (FCPI) play a vital role in addressing various aspects of clinical pharmacy practice. Each committee focuses on specific areas such as education, research, advocacy, and professional development. They work collaboratively to create guidelines, conduct training programs, and promote best practices. By fostering innovation and facilitating communication among members, these committees enhance the overall effectiveness of clinical pharmacy services, ensuring that pharmacists contribute significantly to patient care and public health.
                                </p>

                            </motion.div>
                            <div className="pt-6 lg-img-none">
                                <motion.div
                                    className="relative mar-r-20 "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 8 }}
                                    transition={{
                                        duration: 0.6,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >

                                    <img src={Logo23} alt="Image" className="boarticon" />
                                    <img src={Page1} alt="Left Arrow" className="boardimg rounded-[30px]" />

                                </motion.div>
                            </div>
                        </div>

                        <motion.div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 px-6 md:px-6 pt-8 md:pt-32 mob-32-0 gap-4 md:gap-6 pb-4 md:pb-14 justify-between mar-top-100 "
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 0.6 }}
                            ref={ref}
                        >
                            {boardMembers.map((member, index) => {
                                const { borderColor, bgColor } = boardMembers1[index % boardMembers1.length];


                                const duration = 1 + (index % 3);

                                return (
                                    <motion.div
                                        key={member.id}
                                        className="shrink-0 rounded-[8px] pl-10 pt-8 pr-20 pb-8 minhight"
                                        style={{ borderBottom: `4px solid ${borderColor}`, backgroundColor: bgColor }}
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration, delay: index * 0.3}}
                                    >
                                        <Link to={`/members/${member.slug}`}>
                                            <div className='board-img'>
                                                <img src={BASE_URL + member.image} alt="Image" className="rounded-[150px]" />
                                            </div>
                                            <p className="text-black pt-6 text-start membertext not-italic font-semibold leading-[normal]">{member.name}</p>
                                            <p className="text-black pt-2 text-start text-[16px] not-italic font-normal leading-[normal]">
                                                {getQualification(member.qualification)}
                                            </p>
                                        </Link>
                                    </motion.div>
                                );
                            })}
                        </motion.div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Committees;
