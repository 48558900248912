import React, { useState,useEffect } from 'react';
import { Link, useNavigate ,useLocation } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import login, { getLocal } from '../../../../helpers/auth';
import jwt_decode from 'jwt-decode';
import Log from '../../../../assets/images/login.png';
import axios from 'axios';
import { BASE_URL } from '../../../../Utils/Config';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const [error, setError] = useState('');

    const decodeToken = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    };
    // useEffect(() => {
    //     const checkAuth = () => {
    //         const authToken = localStorage.getItem('authToken');
    //         if (authToken) {
                 
    //             navigate('/dashboard');
    //         }
    //     };
    //     checkAuth();
    // }, [navigate]);
    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     try {
    //         setLoading(true);
    //         const loginResponse = await login(email, password);

    //         if (loginResponse) {
    //             const authToken = localStorage.getItem('authToken');  
    //             const decodedToken = decodeToken(authToken);
    //             const loginRedirectPath = localStorage.getItem('loginRedirectPath');
    //                    if (loginRedirectPath) {
    //                        localStorage.removeItem('loginRedirectPath');
    //                        navigate(loginRedirectPath);
    //                    } else {
    //                         navigate('/dashboard');
    //                     }
    //             if (decodedToken && decodedToken.user_id) {
    //                 const userId = decodedToken.user_id;
    //                 localStorage.setItem('userId', userId);
    //             }

    //             navigate('/dashboard');
    //         }
    //     } catch (error) {
    //              console.error('Error during login:', error);
        
    //                 // Handle errors during login
    //                 if (axios.isAxiosError(error) && error.response) {
    //                     console.log('Error response:', error.response);
    //                     // Show error message as toast notification
    //                     if (error.response.status === 400 && error.response.data && error.response.data.error) {
    //                         toast.error(error.response.data.error);
    //                      } else {
    //                         toast.error('An error occurred during login.');
    //                  }
    //                 } else {
    //                     console.error('Error:', error.message);
    //                     toast.error('An error occurred during login.');
    //                }
        
    //            } finally {
    //                setLoading(false);
    //             }
    //          };
    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        // If a token is found, redirect to dashboard
        if (authToken) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
    
        try {
            const response = await axios.post(`${BASE_URL}/accounts/token/`, {
                email: email,
                password: password
            });
    
           
    
            const authToken = response.data.access;
            localStorage.setItem('authToken', authToken);
    
            const loginRedirectPath = localStorage.getItem('loginRedirectPath');
            if (loginRedirectPath) {
                localStorage.removeItem('loginRedirectPath');
                navigate(loginRedirectPath);
            } else {
                navigate('/dashboard');
            }
    
        } catch (error) {
            
    
            if (axios.isAxiosError(error) && error.response) {
               
    
                const errorMessages = error.response.data.error;
                if (error.response.status === 400 && Array.isArray(errorMessages)) {
                    // Check for specific error message
                    if (errorMessages.includes("Password expired. Please reset your password.")) {
                        
                        
                      
                        toast.error("Password expired. Please reset your password.");
    
                       
                        setTimeout(() => {
                            navigate('/forgopassword');
                        }, 1000);  
                    } else {
                       
                        toast.error(errorMessages[0]);
                    }
                } else {
                    toast.error('An error occurred during login.');
                }
            } else {
                
                toast.error('An error occurred during login.');
            }
    
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <Toaster position="top-center" reverseOrder={false} />

                <div className="pt-6">
                    <div className="flex mob-block bg-white rounded-[30px] p-6 md:p-12 gap-6">
                        <div className="relative xl:w-1/2 lg:w-1/2 md:w-full">
                            <img src={Log} alt="" className="rounded-[30px] w-[764px]" />
                            <p className="absolute left-10 bottom-10 text-[color:var(--White,#FFF)] text-[34px] not-italic font-semibold leading-[36px]">
                                Towards Clinical
                                <br />
                                Pharmacy Excellence
                            </p>
                        </div>
                        <div className="bg-[#f4f4f4] mob-mt-20 rounded-[30px] xl:w-1/2 lg:w-1/2 md:w-full p-6 md:p-8">
                            <div className="text-start">
                                <p className="text-[#222] text-[38px] not-italic font-bold leading-[normal]">Login</p>
                                <p className="text-gray-600 pt-4 text-[18px] not-italic font-semibold leading-[24px]">Email*</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="pt-4">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="border rounded-[10px] px-[20px] py-4 w-full bg-white"
                                            placeholder="Email address"
                                        />
                                    </div>
                                    <p className="text-[#444150] pt-4 text-[18px] not-italic font-semibold leading-[24px]">Password*</p>
                                    <div className="relative pt-4">
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            className="border rounded-[10px] px-[20px] py-4 w-full pt-6 bg-white"
                                            style={{ font: 'normal 1em Arial' }}
                                            placeholder="************"
                                        />
                                    </div>
                                    <div className="pt-8">
                                        <button className="button-bgblues rounded-[10px] w-full py-4" type="submit" disabled={loading}>
                                            {loading ? 'Signing In...' : <p className="text-white text-center text-[20px] not-italic font-semibold leading-[normal]">Sign In</p>}
                                        </button>
                                    </div>
                                </form>
                                <div className="flex items-center justify-center gap-6 pt-6">
                                    <Link to="/forgopassword">
                                        <p className="text-[14px] not-italic px-3 py-1 font-normal leading-[normal]" style={{ display: 'block' }}>Forgot Password</p>
                                    </Link>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;