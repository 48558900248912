import React, { useState, useEffect, useRef } from 'react';
import Logo19 from "../../../assets/images/Upcoming Events (1).png";
import Logo17 from "../../../assets/images/Live Events (1).jpg";
import Logo18 from "../../../assets/images/Completed Events (1).jpg";
import Logoa from "../../../assets/images/add.png";
import Logo from "../../../assets/images/Events (1).jpg";
import Log1 from "../../../assets/images/cancel.png";
import { motion, useAnimation, useInView } from 'framer-motion';
import { BASE_URL } from '../../../Utils/Config';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import pod from "../../../assets/images/podcasts-1.png";
import PodImage1 from '../../../assets/images/pod1.svg';
import PodImage2 from '../../../assets/images/icon-white.svg';
import './Podcast.css';


const Podcast = () => {
    const navigate = useNavigate();
    const [livePodcastsVisible, setLivePodcastsVisible] = useState(true);
    const [upcomingPodcastsVisible, setUpcomingPodcastsVisible] = useState(false);
    const [completedPodcastsVisible, setCompletedPodcastsVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [livePodcasts, setLivePodcasts] = useState([]);
    const [upcomingPodcasts, setUpcomingPodcasts] = useState([]);
    const [completedPodcasts, setCompletedPodcasts] = useState([]);
    const [livePodcastsDropdownVisible, setLivePodcastsDropdownVisible] = useState(null);
    const [upcomingPodcastsDropdownVisible, setUpcomingPodcastsDropdownVisible] = useState(null);
    const [completedPodcastsDropdownVisible, setCompletedPodcastsDropdownVisible] = useState(null);
    const { slug } = useParams();
    const [watchNowVisible, setWatchNowVisible] = useState(false);
    const [podcast, setPodcast] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [podImage, setPodImage] = useState(PodImage1);

    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        const fetchPodcasts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/podcasts_list/`);
             

                setLivePodcasts(response.data.live_podcasts || []);
                setUpcomingPodcasts(response.data.upcoming_podcasts || []);
                setCompletedPodcasts(response.data.completed_podcasts || []);
            } catch (error) {
             
            }
        };

        fetchPodcasts();
    }, []);

    const handleLoginClick = () => {
        const currentPath = window.location.pathname;
        localStorage.setItem('loginRedirectPath', currentPath);
        navigate('/login');
    };
    useEffect(() => {
        if (isLoggedIn) {
            setWatchNowVisible(true);
        } else {
            setWatchNowVisible(false);
        }
    }, [isLoggedIn]);

    const handleJoinFCPI = () => { };

    const handleWatchEvent = () => { };

    const toggleLivePodcasts = () => {
        setLivePodcastsVisible(!livePodcastsVisible);
        setUpcomingPodcastsVisible(false);
        setCompletedPodcastsVisible(false);


    };

    const toggleUpcomingPodcasts = () => {
        setUpcomingPodcastsVisible(!upcomingPodcastsVisible);
        setLivePodcastsVisible(false);
        setCompletedPodcastsVisible(false);


    };

    const toggleCompletedPodcasts = () => {
        setCompletedPodcastsVisible(!completedPodcastsVisible);
        setLivePodcastsVisible(false);
        setUpcomingPodcastsVisible(false);


    };

    const toggleImageVisible = (podcastType, index) => {
        switch (podcastType) {
            case 'live':
                setLivePodcastsDropdownVisible(livePodcastsDropdownVisible === index ? null : index);
                break;
            case 'upcoming':
                setUpcomingPodcastsDropdownVisible(upcomingPodcastsDropdownVisible === index ? null : index);
                break;
            case 'completed':
                setCompletedPodcastsDropdownVisible(completedPodcastsDropdownVisible === index ? null : index);
                break;
            default:
                break;
        }
    };
    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${period}`;
    };




    const [currentTime, setCurrentTime] = useState(new Date());


    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);


    const shouldShowWatchNowButton = (podcast, type) => {

        if (!isLoggedIn) return false;


        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


        const currentTime = toZonedTime(new Date(), timezone);


        const podcastStartTime = new Date(`${podcast.date}T${podcast.starting_time}`);
        const eventStartTimeUTC = toZonedTime(podcastStartTime, 'UTC');
        const eventStartTime = toZonedTime(eventStartTimeUTC, timezone);


        const fifteenMinutesBeforeStart = new Date(eventStartTime.getTime() - 15 * 60 * 1000);


      


        switch (type) {
            case 'live':

                return currentTime >= fifteenMinutesBeforeStart;

            case 'completed':

                return true;

            default:

                return false;
        }
    };



    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6'>

                    <div className='bg-white rounded-[30px]  '>
                        <div className='aspect-[4] bg-card9-background bg-cover to-slate-900 bg-opacity-40 t responsive-card rounded-[30px]'>
                            <div className='z-10 pl-12 pt-32'>
                                <p className='forum-name-head relative  text-white'>Podcasts</p>
                            </div>
                        </div>

                        <motion.div
                            className=" "
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 4 }}
                            transition={{
                                duration: 1.5,
                                ease: "easeInOut",
                            }}
                            ref={ref}
                        >
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 p-p-all">
                                <div
                                    className={`rounded-[30px] ${livePodcastsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D]' : 'bg-gray-100 hover:bg-[#1AB78D]'} cursor-pointer`}
                                    onClick={toggleLivePodcasts}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    <div className="flex p-7 justify-between">
                                        <p className={`text-[#222] text-start ${isHovered || livePodcastsVisible ? 'text-white' : 'hover:text-white'} podcast-text`}>
                                            Live Podcasts
                                        </p>
                                        <img
                                            src={isHovered || livePodcastsVisible ? PodImage2 : PodImage1}
                                            alt="Podcast Logo"
                                            className="rounded-[30px] w-35"
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`rounded-[30px] ${upcomingPodcastsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D]' : 'bg-gray-100 hover:bg-[#1AB78D]'} cursor-pointer`}
                                    onClick={toggleUpcomingPodcasts}
                                    onMouseEnter={() => setIsHovered1(true)}
                                    onMouseLeave={() => setIsHovered1(false)}
                                >
                                    <div className='flex p-7 justify-between'>
                                        <p className={`text-[#222] text-start ${isHovered1 || upcomingPodcastsVisible ? 'text-white' : 'hover:text-white'} podcast-text`}>
                                            Upcoming Podcasts
                                        </p>
                                        <img
                                            src={isHovered1 || upcomingPodcastsVisible ? PodImage2 : PodImage1}
                                            alt="Podcast Logo"
                                            className="rounded-[30px] w-35"
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`rounded-[30px] ${completedPodcastsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D]' : 'bg-gray-100 hover:bg-[#1AB78D]'} cursor-pointer`}
                                    onClick={toggleCompletedPodcasts}
                                    onMouseEnter={() => setIsHovered2(true)}
                                    onMouseLeave={() => setIsHovered2(false)}
                                >
                                    <div className='flex p-7 justify-between'>
                                        <p className={`text-[#222] text-start ${isHovered2 || completedPodcastsVisible ? 'text-white' : 'hover:text-white'} podcast-text `}>
                                            Completed Podcasts
                                        </p>
                                        <img
                                            src={isHovered2 || completedPodcastsVisible ? PodImage2 : PodImage1}
                                            alt="Podcast Logo"
                                            className="rounded-[30px] w-35"
                                        />
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        {livePodcastsVisible && (
                            <div className='pt-16 gap-4'>
                                <div className=' '>
                                    <div className=' '>
                                        <motion.div
                                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 podcast-pad-15 gap-com"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1.5,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            {livePodcasts.map((podcast, index) => (
                                                <div className='   rounded-[10px] ' key={index}>
                                                    <div className='bg-[#f4f4f4] hover:bg-[#f4f4f4] p-8  p-8-mob-pod rounded-[10px]  '>
                                                        <img src={BASE_URL + podcast.banner} alt={podcast.name} className="rounded-[8px] w-[100%]" />
                                                        <p className="text-[#222] pt-4 text-[21px] not-italic font-medium leading-[28px]">
                                                            {podcast.name}
                                                        </p>
                                                        <div className="justify-between gap-[12rem]">
                                                            <p className="text-[#222] text-[16px] pt-4 not-italic font-normal leading-[28px]">{formatDate(podcast.date)}</p>
                                                        </div>
                                                        <p className="text-[color:var(--Gray,#58585A)] pt-4 w-[300px] text-[16px] not-italic font-normal leading-[normal]">
                                                            {formatTime(podcast.starting_time)} to {formatTime(podcast.ending_time)} (IST)
                                                        </p>
                                                        <div className='flex'>
                                                            {!isLoggedIn ? (
                                                                <>

                                                                    <div className='podcast-link'>
                                                                        <p> To Watch This Podcast </p> <p> Please <span className='podcast-login' onClick={handleLoginClick}>Login</span>   or <span> <Link to='/register'>Join FCPI</Link> </span> </p>
                                                                    </div>

                                                                </>
                                                            ) : (
                                                                <>
                                                                    {shouldShowWatchNowButton(podcast, 'live') && (
                                                                             <Link to={`/podcast/${podcast.name.replace(/ /g, '-')}`}>
                                                                            <button onClick={handleWatchEvent} className="bg-[#1AB78D] rounded-[105px] mt-mob px-7 py-1.5">
                                                                                <p className="text-white text-[18px] not-italic font-semibold leading-[normal]">Watch Now</p>
                                                                            </button>
                                                                        </Link>
                                                                    )}




                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            ))}



                                        </motion.div>
                                    </div>
                                    <motion.div
                                        className="  "
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 2,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >

                                    </motion.div>
                                </div>
                            </div>
                        )}

                        {upcomingPodcastsVisible && (
                            <div className='pt-16 gap-4'>
                                <div className=' '>
                                    <div className=' '>
                                        <motion.div
                                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 podcast-pad-15 gap-com"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1.5,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            {upcomingPodcasts.map((podcast, index) => (
                                                <div className='   rounded-[10px] ' key={index}>
                                                    <div className='bg-[#f4f4f4] hover:bg-[#f4f4f4] p-8  p-8-mob-pod rounded-[10px]  '>
                                                        <img src={BASE_URL + podcast.banner} alt={podcast.name} className="rounded-[8px] w-[100%]" />
                                                        <p className="text-[#222] pt-4 text-[21px] not-italic font-medium leading-[28px]">
                                                            {podcast.name}
                                                        </p>
                                                        <div className="justify-between gap-[12rem]">
                                                            <p className="text-[#222] text-[16px] pt-4 not-italic font-normal leading-[28px]">{formatDate(podcast.date)}</p>
                                                        </div>
                                                        <p className="text-[color:var(--Gray,#58585A)] pt-4 w-[300px] text-[16px] not-italic font-normal leading-[normal]">
                                                            {formatTime(podcast.starting_time)} to {formatTime(podcast.ending_time)} (IST)
                                                        </p>
                                                        <div className='flex'>
                                                            {!isLoggedIn ? (
                                                                <>
                                                                    <div className='podcast-link'>
                                                                        <p> To Watch This Podcast </p> <p> Please <span className='podcast-login' onClick={handleLoginClick}>Login</span>   or <span> <Link to='/register'>Join FCPI</Link> </span> </p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {shouldShowWatchNowButton(podcast, 'upcoming') && (
                                                                             <Link to={`/podcast/${podcast.name.replace(/ /g, '-')}`}>
                                                                            <button onClick={handleWatchEvent} className="bg-[#1AB78D] rounded-[105px] mt-mob px-7 py-1.5">
                                                                                <p className="text-white text-[18px] not-italic font-semibold leading-[normal]">Watch Now</p>
                                                                            </button>
                                                                        </Link>
                                                                    )}




                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            ))}



                                        </motion.div>
                                    </div>
                                    <motion.div
                                        className="  "
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 2,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >

                                    </motion.div>
                                </div>
                            </div>
                        )}

                        {completedPodcastsVisible && (
                            <div className='pt-16 gap-4'>
                                <div className=' '>
                                    <div className=' '>
                                        <motion.div
                                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 podcast-pad-15 gap-com"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1.5,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            {completedPodcasts.map((podcast, index) => (
                                                <div className='   rounded-[10px] ' key={index}>
                                                    <div className='bg-[#f4f4f4] hover:bg-[#f4f4f4] p-8 p-8-mob-pod rounded-[10px]  '>
                                                        <img src={BASE_URL + podcast.banner} alt={podcast.name} className="rounded-[8px] w-[100%]" />
                                                        <p className="text-[#222] pt-4 text-[21px] not-italic font-medium leading-[28px]">
                                                            {podcast.name}
                                                        </p>
                                                        <div className="justify-between gap-[12rem]">
                                                            <p className="text-[#222] text-[16px] pt-4 not-italic font-normal leading-[28px]">{formatDate(podcast.date)}</p>
                                                        </div>
                                                        <p className="text-[color:var(--Gray,#58585A)] pt-4 w-[300px] text-[16px] not-italic font-normal leading-[normal]">
                                                            {formatTime(podcast.starting_time)} to {formatTime(podcast.ending_time)} (IST)
                                                        </p>
                                                        <div className='flex mt-4'>
                                                            {!isLoggedIn ? (
                                                                <>
                                                                    <div className='podcast-link'>
                                                                        <p> To Watch This Podcast</p> <p>  Please <span className='podcast-login' onClick={handleLoginClick}>Login</span>   or <span> <Link to='/register'>Join FCPI</Link> </span> </p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {shouldShowWatchNowButton(podcast, 'completed') && (
                                                                        <Link to={`/podcast/${podcast.name.replace(/ /g, '-')}`}>
                                                                        <button onClick={handleWatchEvent} className="bg-[#1AB78D] rounded-[105px] mt-mob px-7 py-1.5">
                                                                            <p className="text-white text-[18px] not-italic font-semibold leading-[normal]">Watch Now</p>
                                                                        </button>
                                                                    </Link>
                                                                    
                                                                    )}



                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            ))}



                                        </motion.div>
                                    </div>
                                    <motion.div
                                        className="  "
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 2,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >

                                    </motion.div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Podcast;
