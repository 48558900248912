import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import Log from "../../../assets/images/Group.svg";
import "./Otp.css";
import { BASE_URL } from "../../../Utils/Config";

const Otp = () => {
    const [otp, setOtp] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const inputRefs = useRef([]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        setUserEmail(emailParam); // Set userEmail state with the email from query parameter
    }, [location.search]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        if (!isNaN(value) && value.length === 1 && otp.length < 4) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp.join(''));

            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleVerify = async () => {
        try {
          

            const response = await axios.post(`${BASE_URL}/accounts/verifyOtp/`, {
                email: userEmail, 
                otp: otp
            });

           

            if (response && response.status === 200 && response.data && response.data.status === 200) {
               
                alert("OTP verification successful!") // Log successful verification
                navigate('/login'); // Navigate to the login page
            } else {
                
                // Display error message to the user
                alert(response.data.error || 'OTP verification failed. Please try again.');
            }
        } catch (error) {
            
            alert('An error occurred while verifying OTP. Please try again later.');
        }
    };

    const handleResendOtp = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/accounts/resendOtp/`, { email: userEmail });

            if (response.status === 200 && response.data && response.data.status === 200) {
                alert('OTP resent successfully. Please check your email.');
                // Clear the OTP input fields
                setOtp('');
                // Focus on the first input field
                if (inputRefs.current[0]) {
                    inputRefs.current[0].focus();
                }
            } else {
                throw new Error('Failed to resend OTP.');
            }
        } catch (error) {
           
            alert('An error occurred while resending OTP. Please try again later.');
        }
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="otp-box">
                <div className="bg-white w-[100%] pb-12  shadow-sm-light rounded-[16px]  ">
                    <div className="">
                        <img src={Log} alt="" className="otp-logo" />
                    </div>
                    <hr className=" w-[100%]" />
                    <div className="text-center mt-6">
                        <p className="text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[normal]">Please Enter OTP</p>
                        <p className="text-[color:var(--Gray,#58585A)] pt-2 text-center text-[16px] not-italic font-normal leading-[20px]">We have sent the verification code to<br />
                            your email address</p>
                        <div className="flex  justify-center gap-4 pt-8">
                            {[...Array(4)].map((_, index) => (
                                <input
                                    key={index}
                                    type="number"
                                    className="input-field-otp"
                                    ref={el => (inputRefs.current[index] = el)}
                                    maxLength={1}
                                    onChange={e => handleChange(e, index)}
                                />
                            ))}
                        </div>
                        <p className="text-[color:var(--Gray,#58585A)] text-[18px] pt-4 not-italic font-medium leading-[24px]">
                            Didn’t receive the OTP? <span style={{ color: '#1AB78D', cursor: 'pointer' }} onClick={handleResendOtp}>Resend</span>
                        </p>
                        <div className="pt-8 ">
                            <button onClick={handleVerify} className="bg-[#00549A] px-24 py-3 rounded-[6px]">
                                <p className=" text-[color:var(--ffffff,#FFF)] text-center text-[18px] not-italic font-semibold leading-[normal]">Verify</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Otp;
