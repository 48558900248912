import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/user-circle.png";
import imag1 from "../../../assets/images/mail.png";
import Icon from "../../../assets/images/Icon frame.png";
import { Link } from "react-router-dom";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import click from "../../../assets/images/click.png";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
// import "./Aforum.css"



const Board = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setmailingAddressActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedBoard, setSelectedBoard] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
         
    });
    const [boards, setBoards] = useState([]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.title]: e.target.value });
    };

    
        const fetchBoards = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/boards/`);
                setBoards(response.data);
            } catch (error) {
                
            }
        };
        useEffect(() => {
        fetchBoards();
    }, []);

 



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('title', formData.title);
             

            const response = await axios.post(`${BASE_URL}/admins/boards/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

           

            alert('Board added successfully!');

            setFormData({
                title: '',
              
            });
            // setEditFormVisible(false);
        } catch (error) {
            
            alert('Failed to add board. Please try again.');
        }
    };

    const handleDelete = async (boardId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this board?');

        if (confirmDelete) {
            try {
                const response = await axios.delete(`${BASE_URL}/admins/boards/${boardId}/delete/`);
               
                alert('Board deleted successfully!');
                fetchBoards()
            } catch (error) {
               
                alert('Failed to delete board. Please try again.');
            }
        }
    };

    const handleEdit = (board) => {
        setSelectedBoard(board);
        setEditFormVisible(!editFormVisible);  
    
      
        setFormData(prevFormData => ({
            ...prevFormData,
            title: board.title,
            
        }));
       
    };
    
    
    

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const postData = new FormData();
            postData.append('title', formData.title);
           
    
            if (!selectedBoard) {
          
                const response = await axios.post(`${BASE_URL}/admins/boards/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                
                alert('Board added successfully!');
            } else {
               
                const response = await axios.put(`${BASE_URL}/admins/boards/${selectedBoard.id}/update/`, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                
                alert('Board updated successfully!');
            }
    
             
            setFormData({
                title: '',
                
            });
            setEditFormVisible(false);
        } catch (error) {
          
            alert('Failed to submit board. Please try again.');
        }
    };
    

    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };

    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add Board</p>
                <div className='flex pt-12 gap-[12rem] p-8'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Board</p>

                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Board</p>

                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Board Name</p>
                                <div className="pt-1">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Board Name"
                                        value={formData.title}
                                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                    />
                                </div>
                                 
                                
                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                        <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'  >Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

                {mailingAddressActive && (
                    <div className=' pt-4'>
                        <div className='bg-gray-100 py-4 w-full flex p-6 gap-[21.5rem]'>
                            <p className='text-[color:var(--Gray,#58585A)] pl-2 text-[18px] not-italic font-semibold leading-[normal]'>Board Name</p>
                            <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold   leading-[normal]'>Actions</p>
                        </div>
                        {boards.map((board, index) => (

                            <div key={index} className='w-full '>
                                <div className='flex pt-4 pb-4 pl-8 '>
                                    <div className='flex gap-4'>
                                        <img src={click} alt="" className='' />
                                        <p className='text-[color:var(--Gray,#58585A)] w-[405px]  text-[16px] not-italic font-normal leading-[normal]'>{board.title}</p>
                                    </div>
                                    <div className='flex gap-6'>
                                        <div>
                                            <img src={ed} alt="" className=' ' onClick={() => handleEdit(board)} />
                                        </div>
                                        <div>
                                            <img src={del} alt="" className=' ' onClick={() => handleDelete(board.id)} />
                                        </div>
                                    </div>
                                </div>
                                <hr className=' ' />
                            </div>
                        ))}

                    </div>
                )}

                {editFormVisible && selectedBoard && (
                    <div className='w-[40%]'>
                        <form onSubmit={handleFormSubmit}>
                            <div className="text-start">
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Board Name</p>
                                <div className="pt-1">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Board Name"
                                        value={formData.title}
                                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                    />
                                </div>
                                 
                                

                               


                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'>
                                        <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Board;
