import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { BASE_URL } from '../../../../Utils/Config';
import { useNavigate } from "react-router-dom";
import Log from '../../../../assets/images/Mask group.png';
import './Forget.css';
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSendOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/accounts/forgot-password/`, { email });
            toast.success(response.data.message);

            navigate(`/otp-forgot?email=${encodeURIComponent(email)}`);
        } catch (error) {
            
            toast.error(error.response.data.error || 'Failed to send OTP. Please try again.');
        }
    };
    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
            
                navigate('/dashboard');
            }
        };
        checkAuth();
    }, [navigate]);

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <Toaster position="top-center" reverseOrder={false} />

                <div className="pt-6">
                    <div className="flex mob-block-forgot bg-white rounded-[30px] p-6 md:p-12 gap-6">
                        <div className="relative">
                            <img src={Log} alt="" className="rounded-[30px]  " />
                            <p className="absolute left-10 bottom-10 text-[color:var(--White,#FFF)] text-[34px] not-italic font-semibold leading-[36px]">
                                Towards Clinical
                                <br />
                                Pharmacy Excellence
                            </p>
                        </div>

                        <div className='forgetpaswd'>
                            <div className="">
                                <div className="bg-[#f4f4f4] fr-section p-6">
                                    <h4 className="text-[#222] font-semibold">Forgot Password</h4>
                                    <div className="text-start">
                                        <p className="text-[#444150] pt-4 text-18px font-semibold">Email*</p>
                                        <div className="relative pt-4">
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                className="forget-input border rounded-10px px-30px py-4 w-516px bg-white"
                                                placeholder="Email address"
                                            />
                                        </div>
                                        <div className="pt-4">
                                            <button onClick={handleSendOtp} className="bg-[#00549A] button-bgblues p-lr-20 rounded-10px w-516px py-4">
                                                <p className="text-white text-center text-20px font-semibold">Send OTP</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;











