import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo6 from "../../../../src/assets/images/image3.png";
import { BASE_URL } from '../../../Utils/Config';
import newssImage from '../../../../src/assets/images/newsppages.png';
import parse from 'html-react-parser';
import "./News.css";


const News = () => {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items per page

    const fetchNews = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/news/`);
            const sortedNews = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            setNews(sortedNews);
            
        } catch (error) {
            
        }
    };

    useEffect(() => {
        fetchNews();
    }, []);

    // Calculate the indices for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = news.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    
    const totalPages = Math.ceil(news.length / itemsPerPage);
 
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] p-all-20'>
                        <div className='overflow-hidden relative rounded-[30px]'>
                            <div className='relative aspect-[4] bg-cover rounded-[30px] flex items-end p-4'>

                                <div className='absolute inset-0 bg-card7-background bg-cover rounded-[30px]'></div>

                                <div
                                    className='absolute inset-0'
                                    style={{
                                        backgroundImage: `url(${newssImage})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                ></div>
                                 

                                <p className='news-head absolute z-20 p-8'>News & Updates</p>
                            </div>


                        </div>
                        <div className='p-12'>
                            <p className='text-[32px] font-semibold '>What’s New</p>

                            {currentItems.map((newsItem, index) => (
                                <div className='pt-10' key={index}>
                                    <div>
                                        <p className='text-[14px] bg-[#C5E5FF] w-[120px] px-5 rounded-[4px] p-1'>{formatDate(newsItem.date)}</p>
                                    </div>
                                    <div className='flex gap-2 pt-4 items-start'>
                                        <img src={Logo6} alt="Logo" className="flex-shrink-0 mt-1" />
                                        <div className='flex-1'>
                                            <p className='text-[#58585A] text-[16px] w-[70%] news-content'>
                                            {parse(newsItem.text)}
                                            </p>
                                        </div>
                                        <hr />
                                    </div>
                                    <hr className='mt-8' />
                                </div>
                            ))}

                       
                            
                            <div className='flex justify-center items-center mt-8 space-x-2'>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className={`px-4 py-2 ${currentPage === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-custom-blue'} text-2xl font-bold`}
                                >
                                    &lt;  
                                </button>
                                {pageNumbers.map((number) => (
                                    <button
                                        key={number}
                                        onClick={() => handlePageChange(number)}
                                        className={`px-4 py-2 border-b-2 ${number === currentPage ? 'border-custom-blue text-custom-blue' : 'border-gray-300 text-gray-700'} text-base`} // Keep numbers at normal thickness
                                    >
                                        {number}
                                    </button>
                                ))}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className={`px-4 py-2 ${currentPage === totalPages ? 'text-gray-300 cursor-not-allowed' : 'text-custom-blue'} text-2xl font-bold`}
                                >
                                    &gt; {/* Right arrow */}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;
