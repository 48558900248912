import React, { useState } from 'react';
import Papa from 'papaparse';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';

const Csv = () => {
    const [csvData, setCsvData] = useState([]);
    const [csvFile, setCsvFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setCsvFile(file);
    };

    const handleFileUpload = () => {
        if (csvFile) {
            Papa.parse(csvFile, {
                complete: (result) => {
                    setCsvData(result.data);
                },
                header: true
            });
        }
    };

    const handleFieldChange = (value, rowIndex, header) => {
        const updatedData = [...csvData];
        if (header === 'phone') {
            updatedData[rowIndex][header] = value.toString(); // Ensure it's treated as a string
        } else {
            updatedData[rowIndex][header] = value;
        }
        setCsvData(updatedData);
    };
    
    

    const formatDateString = (dateString) => {
        const dateObject = new Date(dateString);
        return dateObject.toISOString().slice(0, 19).replace('T', ' ');
    };

    const handleSubmit = async () => {
        try {
            const dataToSend = {
                csvData: csvData.map(row => {
                    const formattedRow = {};
                    Object.keys(row).forEach(key => {
                        // Skip empty fields or fields with undefined values
                        if (row[key]) {
                            formattedRow[key] = key === 'date_joined' ? formatDateString(row[key]) : row[key];
                        }
                    });
                    return formattedRow;
                })
            };

            const response = await axios.post(`${BASE_URL}/accounts/save-csv-data/`, dataToSend);

            
            setSuccessMessage('Data saved successfully');
            setErrorMessage('');
        } catch (error) {
           
            setSuccessMessage('');
            setErrorMessage('Error saving data. Please try again.');
        }
    };

    return (
        <div>
            <h2>Upload CSV File</h2>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload</button>

            {csvData.length > 0 && (
                <div>
                    <h3>CSV Data:</h3>
                    {csvData.map((row, rowIndex) => (
                        <div key={rowIndex} style={{ marginBottom: '10px' }}>
                            {Object.keys(row).map((header, colIndex) => (
                                <div key={colIndex} style={{ marginBottom: '5px' }}>
                                    <label>{header}: </label>
                                    <input
                                        type="text"
                                        value={row[header]}
                                        onChange={(e) => handleFieldChange(e.target.value, rowIndex, header)}
                                        style={{ marginLeft: '5px' }}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                    <button onClick={handleSubmit}>Submit Changes</button>
                    {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                </div>
            )}
        </div>
    );
};

export default Csv;
