import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL } from '../../../../Utils/Config';
import { getUserProfile } from '../../../../helpers/auth';
import pointbg from "../../../../assets/images/point.svg";
import Pointbgmob from "../../../../assets/images/total-point-mob.svg";
import "./MyPoint.css";
import { motion, useAnimation, useInView } from 'framer-motion';

const Points = () => {
    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const [user, setUser] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [eventPoints, setEventPoints] = useState([]);
    const [totalPoints, setTotalPoints] = useState(0);
    const [showAllEvents, setShowAllEvents] = useState(false);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchUserProfileAndEventPoints = async () => {
            try {
                await fetchUserProfile();

                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/accounts/event-points/`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }),
                    axios.get(`${BASE_URL}/accounts/general_event-points/`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    })
                ]);

                const eventPoints1 = response1.data;
                const eventPoints2 = response2.data;
                const combinedEventPoints = [...eventPoints1, ...eventPoints2];

                

                // Sort events by date in descending order
                const sortedEvents = combinedEventPoints.sort((a, b) => new Date(b.event.date) - new Date(a.event.date));
                setEventPoints(sortedEvents);

                // Calculate total points
                let total = 0;
                sortedEvents.forEach(eventPoint => {
                    total += parseFloat(eventPoint.points);
                });
                setTotalPoints(total);
            } catch (error) {
                
            }
        };

        fetchUserProfileAndEventPoints();
    }, [userId, authToken]);

    const fetchUserProfile = async () => {
        try {
            if (userId && authToken) {
                const profile = await getUserProfile(userId);
                setUser(profile.user);
                setUserProfile(profile);
                toast.success('User profile fetched successfully');
            } else {
                toast.error('User ID or authentication token is missing');
            }
        } catch (error) {
            
            toast.error('Error fetching user profile');
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <div className='bg-[#f4f4f4] p-6'>
            <div className='bg-white p-6 point-maindiv'>
                <div className='pont-rightbox'>
                    <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Points</p>
                    <div className='border border-[#E0E0E0] rounded-[12px] mt-4'>
                        <div className='point-head'>
                            <p className='text-[18px] font-semibold'>Earned Points</p>
                        </div>
                        <div className='pall-10'>
                            {eventPoints.slice(0, showAllEvents ? eventPoints.length : 3).map((eventPoint, index) => (
                                <div key={index} className='point-item'>
                                    <div className='point-content'>
                                        <p>
                                            {eventPoint.event?.event_name}
                                            {eventPoint.event?.days > 1 && ` - Day ${eventPoint.day}`}
                                        </p>
                                        <span>{formatDate(eventPoint.date)}</span>
                                    </div>
                                    <div className='point-number'>{eventPoint.points}</div>
                                </div>
                            ))}
                        </div>
                        <div className='point-btn-div'>
                            <button className='point-btn' onClick={() => setShowAllEvents(!showAllEvents)}>
                                {showAllEvents ? 'Show Less' : 'See All Earned Points'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='pont-left mob-d-none'>
                    <motion.div
                        className="total-potn-bg "
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                        }}
                        ref={ref}
                    >
                        <img src={pointbg} alt="Points Background" />
                        <div className='total-pont'>
                            <p>Total Points</p>
                            <div className='point-num'>{totalPoints}</div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Points;
