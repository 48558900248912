import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import con from "../../../assets/images/contact.svg";
import foot3 from "../../../assets/images/mail-at-sign-03.png";
import foot2 from "../../../assets/images/location-07.png";
import con2 from "../../../assets/images/con2.png";
import con3 from "../../../assets/images/con3.png";
import con4 from "../../../assets/images/con-4.png";
import con5 from "../../../assets/images/con5.png";
import con6 from "../../../assets/images/con-6.png";
import { BASE_URL } from '../../../Utils/Config';
import { motion, useAnimation, useInView } from 'framer-motion';
import "./Contact.css";

const Contact = () => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isInView, controls]);
  const authToken = localStorage.getItem('authToken');
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    captchaCode: "",
    typedCaptcha: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaStyles, setCaptchaStyles] = useState([]);

  useEffect(() => {
    generateCaptchaCode();
  }, []);

  const generateCaptchaCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 6;
    let captcha = "";
    let styles = [];
    for (let i = 0; i < length; i++) {
      const char = characters.charAt(Math.floor(Math.random() * characters.length));
      captcha += Math.random() < 0.5 ? char.toLowerCase() : char.toUpperCase();
      styles.push(getRandomStyle());
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      captchaCode: captcha
    }));
    setCaptchaStyles(styles);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const refreshCaptcha = () => {
    generateCaptchaCode();
  };

  const getRandomStyle = () => {
    const colors = ['#333', '#444', '#555', '#666'];
    const fonts = ['Arial', 'Helvetica', 'Verdana', 'Tahoma'];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    const randomFont = fonts[Math.floor(Math.random() * fonts.length)];
    const randomSize = Math.floor(Math.random() * 6 + 16); // Font size between 16px and 22px
    const randomRotation = Math.random() * 6 - 3; // Random rotation between -3deg and 3deg

    return {
      color: randomColor,
      fontFamily: randomFont,
      fontSize: `${randomSize}px`,
      transform: `rotate(${randomRotation}deg)`,
      margin: '0 2px', // Adjust spacing between characters
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Check if authToken is present
    if (!authToken) {
      alert("You need to be logged in to send a message.");
      setIsSubmitting(false);
      return;
    }

    try {
      if (formData.typedCaptcha.trim().toLowerCase() !== formData.captchaCode.trim().toLowerCase()) {
        alert("Please enter the correct CAPTCHA code.");
        setIsSubmitting(false);
        return;
      }

      const response = await axios.post(`${BASE_URL}/accounts/contact/`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

     
      alert("Message sent successfully");
      // Clear form and refresh page or handle UI update
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
        captchaCode: "",
        typedCaptcha: ""
      });
      // window.location.reload();  

    } catch (error) {
   
      alert("An error occurred while submitting the form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <div className="w-full h-full bg-[#f4f4f4]">
      <div className="container mx-auto ">
        <div className="pt-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 ">
            <div className="">
              <div>
                <div className="">

                  <motion.div
                    className=" "
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 4 }}
                    transition={{
                      duration: 1,
                      ease: "easeInOut",
                    }}
                    ref={ref}
                  >
                    <div>
                      <img src={con} alt="" className="rounded-[30px] w-full h-full object-cover" />
                    </div>
                    <div className="pt-6-lg pt-6-md">
                      <div className="bg-[#00549A] rounded-[30px] p-8 mob-padding-1">
                        <div className="text-start">
                          <p className="text-white text-[24px] sm:text-[36px] font-semibold">Get in touch</p>
                          <p className=" p-texr-r">The Federation of Clinical Pharmacists in India (FCPI) founded in 2020, represents a professional and scientific forum for pharmacists, researchers, and educators involved in patient care services.</p>
                          <div className="flex items-start gap-2  pt-6">
                            <div className="pt-1">
                              <img src={foot2} alt="Logo" className="rounded-[30px]" />
                            </div>
                            <p className="text-start text-white text-[14px] sm:text-[17px] font-normal leading-[24px]">
                              No.677, 1st Floor, Suite # 845, 27th Main, <br />
                              13th Cross Road, Sector 1, HSR Layout, Bangalore - 560102
                            </p>
                          </div>
                          <div className="flex items-center gap-2 pt-4 sm:pt-8">
                            <div>
                              <img src={foot3} alt="Logo" className="rounded-[30px]" />
                            </div>
                            <p className="text-start text-white text-[14px] sm:text-[17px] font-normal leading-[24px]">
                              fcpiofficial@gmail.com
                            </p>
                          </div>
                          <div className="flex gap-2 pt-4 sm:pt-8">
                            <img src={con2} alt="" />
                            <img src={con3} alt="" />
                            <img src={con4} alt="" />
                            <img src={con5} alt="" />
                            <img src={con6} alt="" />
                          </div>
                        </div>
                      </div>

                    </div>
                  </motion.div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-[30px] p-6   ">
              <motion.div
                className=" "
                initial={{ opacity: 0 }}
                animate={{ opacity: 4 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
                ref={ref}
              >
                <div>
                  <p className="text-[#222] text-[24px] sm:text-[29px] font-semibold leading-[36px]">
                    Enter your details
                    <br />
                    and we'll get back to you
                  </p>

                  <form onSubmit={handleSubmit}>
                    <div className="pt-6">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="border border-gray-400 rounded-[30px] px-padding py-4 w-full bg-[#F4F4F4]"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="pt-4">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="border border-gray-400 rounded-[30px] px-padding py-4 w-full bg-[#F4F4F4]"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="pt-4">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="border border-gray-400 rounded-[30px] px-padding py-4 w-full bg-[#F4F4F4]"
                        placeholder="Phone"
                        required
                      />
                    </div>
                    <div className="pt-4">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="border border-gray-400 rounded-[30px] px-padding py-4 pb-24 w-full bg-[#F4F4F4]"
                        placeholder="Your message"
                        rows={4}
                        required
                      ></textarea>
                    </div>
                    <div className="pt-4 grid grid-cols-1 lg:grid-cols-2">
                      <div className="bg-[#F4F4F4] rounded-[30px] px-padding1 py-4 flex items-center">
                        <div style={{ display: 'flex', gap: '5px' }}>
                          {formData.captchaCode.split('').map((char, index) => (
                            <span key={index} style={captchaStyles[index]}>
                              {char}
                            </span>
                          ))}
                        </div>
                        <button
                          type="button"
                          onClick={refreshCaptcha}
                          className="ml-4 bg-[#00549A] text-red-500 rounded-[30px] px-4 py-2"
                        >
                          <FontAwesomeIcon icon={faSyncAlt} />
                        </button>

                      </div>


                      <div className="pt-4  ">
                        <input
                          type="text"
                          name="typedCaptcha"
                          value={formData.typedCaptcha}
                          onChange={handleChange}
                          className="border border-gray-400 rounded-[30px] px-padding py-4 w-full bg-[#F4F4F4]"
                          placeholder="Enter CAPTCHA"
                          required
                        />
                      </div>

                    </div>


                    <div className="pt-6">
                      <button
                        type="submit"
                        className={`w-full py-4 submit-btn-certificate   rounded-[30px]  text-white font-semibold text-[16px] sm:text-[18px]`}

                      >
                        <p className="">Submit</p>
                      </button>
                    </div>
                  </form>
                </div>
                </motion.div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
