import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from 'framer-motion';
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules'; // Correct way to import modules
import { BASE_URL } from '../../Utils/Config';
import './Card7.css'; // Add your custom CSS file here

const Card7 = () => {
    const [newsletters, setNewsletters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    const fetchNewsletters = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/newsletters/`);
            setNewsletters(response.data);
        } catch (error) {
          
            setError('Failed to load newsletters. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNewsletters();
    }, []);

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container mx-auto px-4 md:px-8">
                <div className="pt-6">
                    <div className="bg-white rounded-[30px]">
                        <div className="p-10 pl-12 p-m-10">
                            <div className="flex justify-between items-center">
                                <p className="text-black text-start text-[32px] not-italic font-bold leading-[normal]">
                                    Newsletters
                                </p>

                            </div>

                            <p className="text-[#58585A] text-[18px] speciality-text text-start not-italic font-normal">
                                The Federation of Clinical Pharmacists in India (FCPI) publishes newsletters to keep members informed about the latest advancements,<br/> research, and events in clinical pharmacy. These newsletters serve as a vital resource for education, networking,<br/> and sharing best practices within the profession.
                            </p>
                        </div>
                        <motion.div
                            className="p-8 rounded-[30px]"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.6, ease: "easeInOut" }}
                            ref={ref}
                        >
                       
                                <Swiper
                                    spaceBetween={30}
                                    slidesPerView={1}  
                                    breakpoints={{
                                        640: { slidesPerView: 1 },
                                        768: { slidesPerView: 2 },
                                        1024: { slidesPerView: 3 },
                                    }}
                                    navigation
                                    pagination={{ clickable: true }}
                                    modules={[Navigation, Pagination]}
                                    className=" "
                                >
                                    {newsletters.map((newsletter) => (
                                        <SwiperSlide key={newsletter.id}>
                                            <div className="flex justify-center items-center">
                                                <img
                                                    src={newsletter.thumbnail}
                                                    alt={newsletter.title || "Newsletter Image"}
                                                    className="rounded-[30px] object-cover w-full h-full"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card7;
