import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../Utils/Config";
import pro from "../../../assets/images/profile.png";
import { useNavigate } from 'react-router-dom';

const UserType = () => {
    const { userId } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        userrole: '',
        status: '',
        image: ''
    });
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/singleuser/${userId}/`);
                const userData = response.data;

                if (userData && Object.keys(userData).length > 0) {
                    setFormData({
                        first_name: userData.user.first_name || '',
                        last_name: userData.user.last_name || '',
                        email: userData.user.email || '',
                        phone: userData.user.phone || '',
                        userrole: userData.user.userrole || '',
                        status: userData.user.status || '',
                        image: userData.profile.image || ''
                    });

                    if (userData.profile.image) {
                        const imageUrl = BASE_URL + userData.profile.image;
                        setSelectedImage(imageUrl);
                    } else {
                        setSelectedImage(null);
                    }
                } else {
                    toast.error('User profile data is missing');
                }
            } catch (error) {
                toast.error('Error fetching user profile');
            }
        };

        fetchUserProfile();
    }, [userId]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            userrole: e.target.value
        });
    };

    const handleUpdateUserType = async () => {
        const updateData = {
            userrole: formData.userrole,
        };

        try {
            
            const response = await axios.put(`${BASE_URL}/accounts/singleusertype/${userId}/`, updateData);
            alert('User type updated successfully');
        } catch (error) {
            if (error.response && error.response.data) {
                
                alert('Error updating user type: ' + JSON.stringify(error.response.data));
            } else {
                toast.error('Error updating user type');
            }
        }
    };
    const navigate = useNavigate();
    const handleExitEditForm = () => {

        navigate(-1);
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[26px] font-semibold'>Change User Type</p>
                <hr className='mt-6' />
                <div className='flex'>
                    <div className='pt-8'>
                        <img src={selectedImage || (profile?.image ? BASE_URL + profile.image : pro)} alt="" className='w-[300px] rounded-[8px]' />
                    </div>
                    <div>
                        <div className='grid grid-cols-4 gap-[13.2rem] pl-10 pt-8'>
                            <div>
                                <p className='text-[18px] font-medium'>User ID No</p>
                                <p className='text-[16px] font-normal'>{userId}</p>
                            </div>
                            <div>
                                <p className='text-[18px] font-medium'>Name</p>
                                <p className='text-[16px] font-normal'>{formData.first_name} {formData.last_name}</p>
                            </div>
                            <div>
                                <p className='text-[18px] font-medium'>Email</p>
                                <p className='text-[16px] font-normal'>{formData.email}</p>
                            </div>
                            <div>
                                <p className='text-[18px] font-medium'>Mobile</p>
                                <p className='text-[16px] font-normal'>{formData.phone}</p>
                            </div>
                        </div>

                        <hr className='mt-9 ml-10' />

                        <div className='flex gap-[13.2rem] pl-10 pt-8'>
                            <div>
                                <p className='text-[18px] font-medium'>User Type</p>
                                <p className='text-[16px] font-normal'>{formData.userrole}</p>
                            </div>
                            <div>
                                <p className='text-[18px] pl-12 font-medium'>User Status</p>
                                <div className='pt-2 pl-12'>
                                    <button className='px-4 py-2 rounded-[30px]' style={{ background: "rgba(26, 183, 141, 0.10)" }}>
                                        <p className='text-[#1AB78D] text-[14px] font-semibold'>{formData.status}</p>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p className='text-[18px] pl-12 font-medium'>Change User Type</p>
                                <div className="relative pt-2 pl-12">
                                    <select
                                        value={formData.user_type || ''} // Set the value to the current user type or an empty string
                                        onChange={handleInputChange}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-2 w-full bg-[#F4F4F4]"
                                    >
                                        <option value="" disabled>Select User Type</option>
                                        <option value="Basic">Basic</option>
                                        <option value="Admin">Admin</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='flex justify-end gap-4 pt-8'>
                    <button onClick={handleExitEditForm} className='rounded-[6px] px-8 py-3' style={{ border: "1px solid var(--Gray, #58585A)" }}>
                        <p className='text-[18px] font-medium text-[color:var(--Gray,#58585A)]'>Cancel</p>
                    </button>
                    <button onClick={handleUpdateUserType} className='rounded-[6px] px-8 py-3 bg-[#00549A]'>
                        <p className='text-white text-[18px] font-medium'>Update</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserType;
