import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import adm from "../../assets/images/admin.png";
import Log from "../../assets/images/Group.svg";
import { BASE_URL } from "../../Utils/Config";
import { toast, Toaster } from "react-hot-toast";
import { getLocal } from '../../helpers/auth';

const SLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    // useEffect(() => {
    //     const speakerToken = getLocal('speakerAuthToken');
    //     if (speakerToken) {
    //         navigate('/seconduserd');
    //     }
    // }, [navigate]);

 

 

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!username || !password) {
            toast.error("Username and password are required.");
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${BASE_URL}/speaker/login/`, {
                username: username,
                password: password,
            });

            if (response.status === 200) {
                const speakerToken = response.data.access;
                const expirationTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
                const logoutTime = new Date().getTime() + expirationTime;

                localStorage.setItem('speakerAuthToken', speakerToken);
                localStorage.setItem('logoutTime', logoutTime);

                toast.success('Speaker Login Success');
                navigate('/seconduserd');
            } else {
                throw new Error(response.data.error || 'Login failed');
            }
        } catch (error) {
            
            if (error.response && error.response.status === 400) {
                toast.error("Invalid username or password.");
            } else {
                toast.error(error.message || "An error occurred during login. Please try again later.");
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const speakerToken = localStorage.getItem('speakerAuthToken');
        if (!speakerToken) {
            navigate('/speakerlogin');
        } else {
            navigate('/seconduserd');
        }
    }, [navigate]);
    return (
        <div className="w-full">
            <Toaster position="top-center" />
            <div className='flex bg-white'>
                <div className='grad overflow-hidden relative bg-cover h-[100vh]'>
                    <img src={adm} alt="" className='h-[100%] w-[800px] rounded-r-[30px]' />
                    <p className='absolute bottom-28 left-16 z-10 text-[color:var(--White,#FFF)] text-[44px] not-italic font-semibold leading-[53px]'>Towards Clinical <br />
                        Pharmacy Excellence</p>
                    <p className='absolute bottom-16 left-16 z-10 text-[color:var(--Lightgray,#F4F4F4)] text-[14px] not-italic font-normal leading-[normal]'>© 2024 All rights reserved</p>
                </div>
                <div className='p-56'>
                    <div className=" ">
                        <img src={Log} alt="" className="" /></div>
                    <div className="bg-white rounded-[30px]">
                        <div className="text-start pt-8">
                            <p className="text-[#222] text-[36px] not-italic font-bold leading-[normal]">Speaker Panel</p>
                            <p className="text-gray-600 pt-4 text-[18px] not-italic font-semibold leading-[24px]">Username</p>
                            <form onSubmit={handleSubmit}>
                                <div className="pt-4">
                                    <input
                                        type="text"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-[456px] bg-[#F4F4F4]"
                                        placeholder="Enter Your Username"
                                    />
                                </div>
                                <p className="text-[#444150] pt-4 text-[18px] not-italic font-semibold leading-[24px]">Password*</p>
                                <div className="relative pt-4">
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        className="border rounded-[6px] px-[20px] py-4 w-full pt-6 bg-white"
                                        style={{ font: 'normal 1em Arial' }}
                                        placeholder="************"
                                    />
                                </div>
                                <div className='pt-8'>
                                    <button type="submit" className='submit-btn-certificate rounded-[6px] w-full py-4' disabled={loading}>
                                        {loading ? "Signing In..." : <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Sign In</p>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SLogin;
