import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";
import axios from 'axios';
import { BASE_URL } from "../../../Utils/Config";
import edit from "../../../assets/images/edit-user.png";
import log from "../../../assets/images/Frame.png";
import { useNavigate } from 'react-router-dom';
import pro from "../../../assets/images/profile.png"


const Profile = () => {
    const { userId } = useParams(); // Get userId from URL parameters
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        date_of_birth: '',
        primary_position: '',
        state: '',
        primary_pharmacy_degree: '',
        secondary_pharmacy_degree: '',
        additional_degrees: '',
        city: '',
        country: '',
        pharmacy_college_name: '',
        pharmacy_college_degree: '',
        current_work_institution: '',
        pincode: '',
        image: ''
    });
    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const [editFormVisible, setEditFormVisible] = useState(false);


    const [profile, setProfile] = useState(null);


    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            const imageUrl = URL.createObjectURL(imageFile);
            setSelectedImage(imageUrl);

            setFormData(prevFormData => ({
                ...prevFormData,
                image: imageFile,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formDataToSend = new FormData();
    
        // Append each field to FormData, allowing empty strings for optional fields
        Object.entries(formData).forEach(([key, value]) => {
            // If the value is empty for optional fields, you can decide whether to append them or not
            if (value !== undefined && value !== null) {
                formDataToSend.append(key, value);
            }
        });
    
        // Check for the image field separately, only append if it's a valid file
        if (formData.image instanceof File && formData.image.size > 0) {
            formDataToSend.append('image', formData.image);
        }
    
        try {
            const response = await axios.put(`${BASE_URL}/accounts/user/userprofile/${userId}/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            
            toast.success('User profile updated successfully!');
            window.history.back();
        } catch (error) {
           
            toast.error('Failed to update user profile.');
        }
    };
    
    
    







    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/singleuser/${userId}/`);
                const userData = response.data;
               
                if (userData && Object.keys(userData).length > 0) {
                    setFormData({
                        first_name: userData.user.first_name || '',
                        last_name: userData.user.last_name || '',
                        date_of_birth: userData.profile.date_of_birth || '',
                        primary_position: userData.profile.primary_position || '',
                        state: userData.profile.state || '',
                        primary_pharmacy_degree: userData.profile.primary_pharmacy_degree || '',
                        secondary_pharmacy_degree: userData.profile.secondary_pharmacy_degree || '',
                        additional_degrees: userData.profile.additional_degrees || '',
                        city: userData.profile.city || '',
                        country: userData.profile.country || '',
                        pharmacy_college_name: userData.profile.pharmacy_college_name || '',
                        pharmacy_college_degree: userData.profile.pharmacy_college_degree || '',
                        current_work_institution: userData.profile.current_work_institution || '',
                        pincode: userData.profile.pincode || '',
                        image: userData.profile.image || ''
                    });
                    if (userData.profile.image) {
                        const imageUrl = BASE_URL + userData.profile.image;
                        setSelectedImage(imageUrl);
                    } else {
                        setSelectedImage(null);
                    }
                } else {
                    
                    toast.error('User profile data is missing');
                }
            } catch (error) {
               
                toast.error('Error fetching user profile');
            }
        };

        fetchUserProfile();
    }, [userId]);

    const navigate = useNavigate();

    const handleExitEditForm = () => {
        
        navigate(-1);  
    };





    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <Toaster position="top-center reverseOrder={false}" />
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Profile Edit</p>
                <form onSubmit={handleSubmit}>
                    <div className="pt-4">
                        <div className='flex'>
                            <div>
                                <img src={selectedImage || (profile?.image ? BASE_URL + profile.image : pro)} alt="" className='w-[300px] rounded-[8px]' />



                                <div className="pt-1">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                    />
                                </div>
                            </div>
                            <div className='grid grid-cols-2 w-full'>
                                <div className='pl-10'>
                                    <div className=''>

                                        <div className="text-start">
                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>First Name</p>
                                            <div className="pt-2">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="First Name"
                                                />

                                            </div>
                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Date of Birth</p>
                                            <div className="pt-2">
                                                <input
                                                    type="date"
                                                    name="date_of_birth"
                                                    value={formData.date_of_birth}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Date of Birth"
                                                />


                                            </div>

                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>State</p>
                                            <div className="pt-2">
                                                <input
                                                    type="text"
                                                    name="state"
                                                    value={formData.state}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="State"
                                                />
                                            </div>



                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Primary Pharmacy Degree</p>
                                            <div className="relative pt-2">
                                                <select
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    name="primary_pharmacy_degree"
                                                    value={formData.primary_pharmacy_degree} // Use value instead of defaultValue
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="" disabled>Select Primary Pharmacy Degree</option>
                                                    <option value="Bachelors in Pharmacy">Bachelors in Pharmacy</option>
                                                    <option value="Doctor of Pharmacy">Doctor of Pharmacy</option>
                                                </select>


                                            </div>

                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Secondary Pharmacy Degree</p>
                                            <div className="relative pt-2">
                                                <select
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    name="secondary_pharmacy_degree"
                                                    value={formData.secondary_pharmacy_degree}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="" disabled>Select secondaryPharmacyDegree</option>
                                                    <option value="Doctor of Pharmacy Post Baccalaureate">Doctor of Pharmacy Post Baccalaureate</option>
                                                    <option value="Masters in Pharmacy">Masters in Pharmacy</option>

                                                </select>

                                            </div>
                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Additional Degrees</p>
                                            <div className="pt-2">
                                                <input
                                                    type="text"
                                                    name="additional_degrees"
                                                    value={formData.additional_degrees}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Additional Degrees"
                                                />
                                            </div>
                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>current_work_institution</p>
                                            <div className="pt-2">
                                                <input
                                                    type="text"
                                                    name="current_work_institution"
                                                    value={formData.current_work_institution}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Current Work Institution"
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className='pl-10'>
                                    <div className=''>

                                        <div className="text-start">
                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Last Name</p>
                                            <div className="pt-2">

                                                <input
                                                    type="text"
                                                    name="last_name"  // Make sure the name attribute matches the key in formData
                                                    value={formData.last_name}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Last Name"
                                                />

                                            </div>

                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>Primary Position</p>
                                            <div className="pt-2 relative">
                                                <div className="relative">
                                                    <select
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                        name="primary_position"
                                                        value={formData.primary_position}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="" disabled selected>Select Primary Position</option>
                                                        <option value="student">student</option>
                                                        <option value="clinical pharmacist">clinical pharmacist</option>
                                                        <option value="pharmacist">pharmacist</option>
                                                        <option value="faculty">faculty</option>

                                                        {/* Add more options as needed */}
                                                    </select>
                                                </div>

                                            </div>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>City</p>
                                            <div className="relative pt-2">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="City"
                                                />
                                            </div>




                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Country</p>
                                            <div className="relative pt-2">
                                                <input
                                                    type="text"
                                                    name="country"
                                                    value={formData.country}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Country"
                                                />
                                            </div>
                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Pincode</p>
                                            <div className="relative pt-2">
                                                <input
                                                    type="text"
                                                    name="pincode"
                                                    value={formData.pincode}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Pincode"
                                                />
                                            </div>
                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Name of Pharmacy College</p>
                                            <div className="pt-2 relative">
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name="pharmacy_college_name"
                                                        value={formData.pharmacy_college_name}
                                                        onChange={handleInputChange}
                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                        placeholder="pharmacyCollegeName"
                                                    />
                                                </div>

                                            </div>
                                            <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Name of secondary college</p>
                                            <div className=" relative pt-2">

                                                <input
                                                    type="text"
                                                    name="pharmacy_college_degree"
                                                    value={formData.pharmacy_college_degree}
                                                    onChange={handleInputChange}
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Pharmacy College Degree"
                                                />
                                            </div>

                                            <div className='pt-16 flex'>




                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=' flex gap-4 justify-center'>
                        <button type="submit" className='submit-btn-editprofile rounded-[10px] '>
                            <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Submit</p>
                        </button>

                        <button
                            type="button"
                            onClick={handleExitEditForm}
                            className='exit-btn-certificate rounded-[10px] bg-gray-500 w-[200px] py-6'
                        >
                            <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Exit</p>
                        </button>




                    </div>
                </form>

            </div>
        </div>
    );
};

export default Profile;
