import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import { motion, useAnimation, useInView } from 'framer-motion';
import yout from "../../../assets/images/youtu.png";
import Logo1 from "../../../assets/images/calendar-03.png";
import eye from '../../../assets/images/chat-eye.png';

const PodcastDetail = () => {
    const navigate = useNavigate();
    const { name } = useParams();
    const [podcast, setPodcast] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userCount, setUserCount] = useState(0);

    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                navigate('/login');
            }
        };
        checkAuth();
    }, [navigate]);

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchPodcast = async () => {
            try {
                const decodedName = name.replace(/-/g, ' ');
                const response = await axios.get(`${BASE_URL}/admins/podcast/${decodedName}/`);
                setPodcast(response.data);
                setLoading(false);
            } catch (error) {
           
                setError('Failed to fetch podcast details.');
                setLoading(false);
            }
        };

        fetchPodcast();
    }, [name]);

    useEffect(() => {
        const storedCount = localStorage.getItem('userCount') || 0;
        const updatedCount = parseInt(storedCount) + 1;
        setUserCount(updatedCount);
        localStorage.setItem('userCount', updatedCount);

        return () => {
            const finalCount = updatedCount - 1;
            localStorage.setItem('userCount', finalCount);
            setUserCount(finalCount);
        };
    }, []);

    const formatTime = (timeString) => {
        if (!timeString) return 'Invalid time';
        const [hours, minutes] = timeString.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${period}`;
    };

    const extractYouTubeId = (url) => {
        if (!url) return '';
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|live\/)?([^"&?\/\s]{11})|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^"&?\/\s]{11})/);
        return match ? (match[1] || match[2]) : '';
    };

    const videoId = extractYouTubeId(podcast?.youtube_url);
    const videoUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : '';

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container mx-auto px-4 md:px-8">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] p-4 md:p-10'>
                        <div className='relative rounded-[30px]'>
                            <div className=''>
                                <motion.div
                                    ref={ref}
                                    animate={controls}
                                    initial={{ opacity: 0, y: 50 }}
                                >
                                    <p className='text-[#00549A] font-40 font-bold m-pt-6-10'>{podcast?.name}</p>
                                    <div className='flex  md:flex-row justify-between pt-6 m-pt-6-0'>
                                        
                                        <div className='flex gap-4 md:gap-12 mt-4 md:mt-0 mobile-pod-block-m'>
                                            <div className='flex items-center gap-4'>
                                                <img src={Logo1} alt="Calendar" className="pt-4" />
                                                <div className='flex gap-4 pt-1 '>
                                                    <p className='text-[#222222] pt-4 text-[14px] md:text-[17px] not-italic font-normal leading-[normal]'>{podcast?.date}</p>
                                                    <p className='text-[#222222] pt-4 text-[14px] md:text-[17px] not-italic font-normal leading-[normal]'>
                                                        {formatTime(podcast?.starting_time)} to {formatTime(podcast?.ending_time)} (IST)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='border h-[70px] md:h-[85px] px-4 py-2 rounded-[10px] flex flex-col justify-center mt-m-2-20'>
                                                <div className='flex gap-2 pt-2'>
                                                    <img src={eye} alt="" className="w-6 h-6" />
                                                    <p className='text-[#00549A] text-[18px] md:text-[22px]'>{userCount}</p>
                                                </div>
                                                <p className='text-[#58585A] text-[12px] md:text-[14px]'>People are currently watching</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='mt-2' />
                                    <div className="p-6 md:p-12 m-p6-10">
                                        {videoId ? (
                                            <div className="relative" style={{ paddingTop: '56.25%' }}>
                                                <iframe
                                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
                                                    src={videoUrl}
                                                    title="Podcast Video"
                                                    allowFullScreen
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                ></iframe>
                                            </div>
                                        ) : (
                                            <p>No YouTube video available</p>
                                        )}
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 md:gap-12 p-6 md:p-12'>
                                        {/* Hosts Section */}
                                        <div>
                                            <h2 className="text-[20px] md:text-[26px] mb-3 text-[#58585A] font-semibold">Hosts :</h2>
                                            {podcast?.hosts && podcast.hosts.length > 0 ? (
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-6 overflow-x-auto">
                                                    {podcast.hosts.map((host) => (
                                                        <div key={host.id} className="flex-shrink-0 flex flex-col items-center mx-2 my-2">
                                                            {host.photo && (
                                                                <img
                                                                    src={BASE_URL + host.photo}
                                                                    alt={host.name}
                                                                    className="host-img rounded-full"
                                                                />
                                                            )}
                                                            <p className='text-[16px] md:text-[18px] text-center mt-2 p-3'>{host.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p>No hosts available</p>
                                            )}
                                        </div>

                                        {/* Guests Section */}
                                        <div>
                                            <h2 className="text-[20px] md:text-[26px] text-[#58585A] font-semibold mb-3">Guests :</h2>
                                            {podcast?.guests && podcast.guests.length > 0 ? (
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-6 overflow-x-auto">
                                                    {podcast.guests.map((guest) => (
                                                        <div key={guest.id} className="flex-shrink-0 flex flex-col items-center mx-2 my-2">
                                                            {guest.photo && (
                                                                <img
                                                                    src={BASE_URL + guest.photo}
                                                                    alt={guest.name}
                                                                    className="w-24 h-24 md:w-28 md:h-28 rounded-full"
                                                                />
                                                            )}
                                                            <p className='text-[16px] md:text-[18px] text-center mt-2 p-3'>{guest.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p>No guests available</p>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PodcastDetail;
