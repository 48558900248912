import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import Log from "../../../../assets/images/Group.svg";
 
import { BASE_URL } from "../../../../Utils/Config";
import { toast } from 'react-hot-toast';

const OtpVerification = () => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const inputRefs = useRef([]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        setUserEmail(emailParam); // Set userEmail state with the email from query parameter
    }, [location.search]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        if (!isNaN(value) && value.length === 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleVerify = async () => {
        const otpCode = otp.join('');
        try {
      

            const response = await axios.post(`${BASE_URL}/accounts/verify-forgot-password-otp/`, {
                email: userEmail, 
                otp: otpCode
            });

          

            if (response && response.status === 200 && response.data && response.data.status === 200) {
                
                toast.success('OTP verification successful!');
                navigate(`/reset-password?email=${userEmail}`); // Navigate to the reset password page
            } else {
               
                toast.error(response.data.error || 'OTP verification failed. Please try again.');
            }
        } catch (error) {
           
            toast.error('An error occurred while verifying OTP. Please try again later.');
        }
    };

    const handleResendOtp = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/accounts/resendOtp/`, { email: userEmail });

            if (response.status === 200 && response.data && response.data.status === 200) {
                toast.success('OTP resent successfully. Please check your email.');
                // Clear the OTP input fields
                setOtp(['', '', '', '']);
                // Focus on the first input field
                if (inputRefs.current[0]) {
                    inputRefs.current[0].focus();
                }
            } else {
                throw new Error('Failed to resend OTP.');
            }
        } catch (error) {
           
            toast.error('An error occurred while resending OTP. Please try again later.');
        }
    };
    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
            
                navigate('/dashboard');
            }
        };
        checkAuth();
    }, [navigate]);
    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="otp-box">
                <div className="bg-white w-[100%] pb-12 shadow-sm-light rounded-[16px]">
                    <div className="">
                        <img src={Log} alt="OTP Logo" className="otp-logo" />
                    </div>
                    <hr className="w-[100%]" />
                    <div className="text-center mt-6">
                        <p className="text-[#222] text-[24px] font-semibold">Please Enter OTP</p>
                        <p className="text-[#58585A] pt-2 text-[16px] font-normal leading-[20px]">
                            We have sent the verification code to<br />
                            your email address
                        </p>
                        <div className="flex justify-center gap-4 pt-8">
                            {[...Array(4)].map((_, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    className="input-field-otp"
                                    ref={el => (inputRefs.current[index] = el)}
                                    maxLength={1}
                                    value={otp[index]}
                                    onChange={e => handleChange(e, index)}
                                />
                            ))}
                        </div>
                        <p className="text-[#58585A] text-[18px] pt-4 font-medium leading-[24px]">
                            Didn’t receive the OTP? <span style={{ color: '#1AB78D', cursor: 'pointer' }} onClick={handleResendOtp}>Resend</span>
                        </p>
                        <div className="pt-8">
                            <button onClick={handleVerify} className="bg-[#00549A] px-24 py-3 rounded-[6px]">
                                <p className="text-white text-[18px] font-semibold">Verify</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpVerification;
