import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../../Utils/Config';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './Ucertificate.css';
import { useReactToPrint } from 'react-to-print';
import down from '../../../../assets/images/download-05.png';
import { motion, useAnimation, useInView } from 'framer-motion';

const Certificate = () => {
    const [userDetails, setUserDetails] = useState({});
    const [ecertificates, setEcertificates] = useState([]);
    const [generalCertificates, setGeneralCertificates] = useState([]);
    const [error, setError] = useState('');
    const [certificateIndexToPrint, setCertificateIndexToPrint] = useState(null);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    const contentToPrint = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentToPrint.current,
    });

    const printCertificate = (index) => {
        setCertificateIndexToPrint(index);
        handlePrint();
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        
        const day = String(date.getDate()).padStart(2, '0');  // Ensure two-digit day
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()]; // Get month in "Oct" format
        const year = date.getFullYear();           // Get full year
        
        return `${day}-${month}-${year}`;
    }
    
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) {
                    setError('Authentication token not found.');
                    return;
                }

                const response = await axios.get(`${BASE_URL}/accounts/usercertificate/`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });
                setUserDetails(response.data);
            } catch (error) {
                setError('An error occurred while fetching user details.');
            }
        };

        const fetchEventCertificates = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) {
                    setError('Authentication token not found.');
                    return;
                }

                const response = await axios.get(`${BASE_URL}/accounts/ecertificate/`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });

                setEcertificates(response.data);
            } catch (error) {
                setError(' ');
            }
        };

        const fetchGeneralCertificates = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) {
                    setError('Authentication token not found.');
                    return;
                }

                const response = await axios.get(`${BASE_URL}/accounts/general_ecertificate/`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });

                setGeneralCertificates(response.data);
            } catch (error) {
                setError(' ');
            }
        };

        fetchUserDetails();
        fetchEventCertificates();
        fetchGeneralCertificates();
    }, []);

    const downloadCertificate = async (certificate) => {
        try {
            if (!certificate || !certificate.certificate_image) {
                throw new Error('Certificate data is invalid.');
            }
    
            const response = await axios.get(BASE_URL + certificate.certificate_image, {
                responseType: 'arraybuffer',
            });
    
            if (!response || !response.data || !response.data.byteLength) {
                throw new Error('Empty response or no data received.');
            }
    
            const imageBlob = new Blob([response.data], { type: 'image/png' });
            const imageData = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(imageBlob);
            });
    
            const img = new Image();
            img.src = imageData;
            img.onload = function () {
                const targetWidth = 720;
                const targetHeight = 507;
                const margin = 50;
    
                const pdfDoc = new jsPDF({
                    orientation: 'landscape',
                    unit: 'px',
                    format: [targetWidth, targetHeight],
                });
    
                pdfDoc.addImage(imageData, 'PNG', 0, 0, targetWidth, targetHeight);
    
                // Right align text with individual offsets
                const rightAlignText = (text) => {
                    const textWidth = pdfDoc.getTextWidth(text);
                    return targetWidth - textWidth - margin;
                };
    
                // Username
                const usernameText = `${userDetails.first_name || ''} ${userDetails.last_name || ''}`;
                pdfDoc.setFontSize(25);
                pdfDoc.setTextColor(26, 187, 141);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(usernameText, rightAlignText(usernameText) - 10, margin + 218);
    
                // Event Name
                const eventName = certificate.event_days > 1 
                    ? `${certificate.event_name} (Day ${certificate.single_event?.day || ''})` 
                    : certificate.event_name;
                pdfDoc.setFontSize(21);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(eventName, rightAlignText(eventName) - 10, margin + 261);  
    
                // Forum Name
                const forumName = certificate.forum || '';
                pdfDoc.setFontSize(21);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(forumName, rightAlignText(forumName) - 10, margin + 303); 
    
                // Event Date
                const eventDateText = formatDate(certificate.event_date);
                pdfDoc.setFontSize(19);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(eventDateText, rightAlignText(eventDateText) - 10, margin + 323);  
    
                // Points
                const pointsValue = certificate.single_event?.points ? certificate.single_event.points.toFixed(2) : '0.00';
                pdfDoc.setFontSize(24);
                pdfDoc.setTextColor(26, 187, 141);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(pointsValue, rightAlignText(pointsValue) - 567, margin + 405); 
    
                // Feedback Created At (new addition)
                const feedbackDate = formatDate(certificate.feedback_created_at);
                pdfDoc.setFontSize(18);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(feedbackDate, rightAlignText(feedbackDate) - 428, margin + 405); 
    
                pdfDoc.save(`certificate_${certificate.event_name}.pdf`);
            };
        } catch (error) {
           
        }
    };
    
    const downloadCertificate1 = async (certificate) => {
        try {
            if (!certificate || !certificate.certificate_image) {
                throw new Error('Certificate data is invalid.');
            }
    
            const response = await axios.get(BASE_URL + certificate.certificate_image, {
                responseType: 'arraybuffer',
            });
    
            if (!response || !response.data || !response.data.byteLength) {
                throw new Error('Empty response or no data received.');
            }
    
            const imageBlob = new Blob([response.data], { type: 'image/png' });
            const imageData = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(imageBlob);
            });
    
            const img = new Image();
            img.src = imageData;
            img.onload = function () {
                const targetWidth = 720;
                const targetHeight = 507;
                const margin = 50;
    
                const pdfDoc = new jsPDF({
                    orientation: 'landscape',
                    unit: 'px',
                    format: [targetWidth, targetHeight],
                });
    
                pdfDoc.addImage(imageData, 'PNG', 0, 0, targetWidth, targetHeight);
    
                // Right align text with individual offsets
                const rightAlignText = (text) => {
                    const textWidth = pdfDoc.getTextWidth(text);
                    return targetWidth - textWidth - margin;
                };
    
                // Username
                const usernameText = `${userDetails.first_name || ''} ${userDetails.last_name || ''}`;
                pdfDoc.setFontSize(25);
                pdfDoc.setTextColor(26, 187, 141);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(usernameText, rightAlignText(usernameText) - 10, margin + 218);
    
                // Event Name
                const eventName = certificate.event_days > 1 
                    ? `${certificate.event_name} (Day ${certificate.single_event?.day || ''})` 
                    : certificate.event_name;
                pdfDoc.setFontSize(21);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(eventName, rightAlignText(eventName) - 10, margin + 261);  
    
                // Forum Name
                const forumName = certificate.forum || '';
                pdfDoc.setFontSize(21);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(forumName, rightAlignText(forumName) - 10, margin + 303); 
    
                // Event Date
                const eventDateText = formatDate(certificate.event_date);
                pdfDoc.setFontSize(19);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(eventDateText, rightAlignText(eventDateText) - 10, margin + 303);  
    
                // Points
                const pointsValue = certificate.single_event?.points ? certificate.single_event.points.toFixed(2) : '0.00';
                pdfDoc.setFontSize(24);
                pdfDoc.setTextColor(26, 187, 141);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(pointsValue, rightAlignText(pointsValue) - 567, margin + 405); 
    
                // Feedback Created At (new addition)
                const feedbackDate = formatDate(certificate.feedback_created_at);
                pdfDoc.setFontSize(18);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                pdfDoc.text(feedbackDate, rightAlignText(feedbackDate) - 428, margin + 405); 
    
                pdfDoc.save(`certificate_${certificate.event_name}.pdf`);
            };
        } catch (error) {
           
        }
    };
    

    return (
        <div className='bg-[#f4f4f4] p-6'>
            <div className='bg-white p-6'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>
                    Certificates
                </p>
                <hr className='mt-8' />

                {/* Event Certificates Section */}
                <motion.div
                    className="mt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                    }}
                    ref={ref}
                >
           
                    {ecertificates.length > 0 ? (
                        ecertificates.map((certificate, index) => {
                            return (
                                <div key={index} className='flex justify-between m-cer-block-m pt-6' id={`ecertificate-${index}`}>
                                    <div className='text-[20px] font-semibold'>
                                        {certificate.event_days > 1 
                                            ? `${certificate.event_name} (Day ${certificate.single_event.day})` 
                                            : certificate.event_name}
                                    </div>
                                    <div className=' m-mt-100'>
                                        <button className='border p-3 w-[197px] border-[#00549A] rounded-[4px] flex justify-between'>
                                            <p className='text-[#00549A] font-medium text-[14px]' onClick={() => downloadCertificate(certificate)}>
                                                Download Certificate
                                            </p>
                                            <img src={down} alt="Download" />
                                        </button>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p> </p>
                    )}
                </motion.div>

              
                <motion.div
                    className="mt-8"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                    }}
                    ref={ref}
                >  
               
                    {generalCertificates.length > 0 ? (
                        generalCertificates.map((certificate, index) => {
                            return (
                                <div key={index} className='flex justify-between m-cer-block-m pt-6' id={`general-certificate-${index}`}>
                                    <div className='text-[20px] font-semibold'>
                                        {certificate.event_days > 1 
                                            ? `${certificate.event_name} (Day ${certificate.single_event.day})` 
                                            : certificate.event_name}
                                    </div>
                                    <div className='m-mt-100'>
                                        <button className='border p-3 w-[197px] border-[#00549A] rounded-[4px] flex justify-between'>
                                            <p className='text-[#00549A] font-medium text-[14px]' onClick={() => downloadCertificate1(certificate)}>
                                                Download Certificate
                                            </p>
                                            <img src={down} alt="Download" />
                                        </button>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p> </p>
                    )}
                </motion.div>

                {error && <p className="text-gray-400">{error}</p>}
            </div>
        </div>
    );
};

export default Certificate;
