import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/user-circle.png";
import imag1 from "../../../assets/images/mail.png";
import axios from 'axios';
import { BASE_URL } from "../../../Utils/Config";
import { toast, Toaster } from "react-hot-toast";
import { Link } from 'react-router-dom';

const SecondUser = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setmailingAddressActive] = useState(false);
    const [userRoleActive, setuserRoleActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [clickedStates, setClickedStates] = useState(Array(3).fill(false));
    const [dropdownStates, setDropdownStates] = useState(Array(3).fill(false));
    const [imagePositionY, setImagePositionY] = useState(0);
    const [userRoles, setUserRoles] = useState([]);
    const [imagePositionX, setImagePositionX] = useState(0);
    const [userData, setUserData] = useState();
    const [selectedRole, setSelectedRole] = useState("");


    const [formData, setFormData] = useState({
        username: '',
        password: '',
        password2: ''
    });

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/speaker/second-users/`);
            const fetchedUserData = response.data;
            setUserData(fetchedUserData);
            
        } catch (error) {
            
            toast.error('Error fetching user data');
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const [generatedPassword, setGeneratedPassword] = useState("");

    const generatePassword = () => {
        const length = 8;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let newPassword = "";
        for (let i = 0; i < length; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        setFormData({
            ...formData,
            password: newPassword,
            password2: newPassword
        });
        setGeneratedPassword(newPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { username, password, password2 } = formData;

        if (!username || !password || !password2) {
            toast.error("All fields are required.");
            return;
        }

        if (password.trim() !== password2.trim()) {
            toast.error("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/speaker/createuser/`, {
                username,
                password,
            });
            
            if (response.status === 201) {
                toast.success("Registration successful.");
                fetchUserData();
                setFormData({
                    username: '',
                    password: '',
                    password2: ''
                });
            } else {
                toast.error("Something went wrong during registration.");
            }

        } catch (error) {
            
            if (error.response && error.response.data && error.response.data.errors) {
                const errorMessages = Object.values(error.response.data.errors).flat();
                errorMessages.forEach(errorMessage => toast.error(errorMessage));
            } else {
                toast.error("An error occurred during registration.");
            }
        }
    };

    const handleStatusChange = async (userId, currentStatus) => {
        const confirmAction = window.confirm(`Are you sure you want to ${currentStatus === 'Active' ? 'deactivate' : 'activate'} this user?`);
        if (!confirmAction) return;

        try {
            const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';
            const response = await axios.patch(`${BASE_URL}/speaker/second-users/${userId}/toggle-status/`, { status: newStatus });
            if (response.status === 200) {
                const updatedUserData = userData.map(user => {
                    if (user.id === userId) {
                        return { ...user, status: newStatus };
                    }
                    return user;
                });
                setUserData(updatedUserData);
                toast.success("User status updated successfully.");
            } else {
                toast.error("Failed to update user status.");
            }
        } catch (error) {
           
            toast.error("An error occurred while updating user status.");
        }
    };

    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);
        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
            setuserRoleActive(false);
        }
    };

    const handleDeleteUser = async (userId) => {
        const confirmAction = window.confirm("Are you sure you want to delete this user?");
        if (!confirmAction) return;

        try {
            await axios.delete(`${BASE_URL}/speaker/second-users/${userId}/`);
            const updatedUserData = userData.filter(user => user.id !== userId);
            setUserData(updatedUserData);
            toast.success("User deleted successfully.");
            fetchUserData()
        } catch (error) {
           
            toast.error("An error occurred while deleting user.");
        }
    };

    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };

    const toggleUserStatus = (index) => {

        const updatedUserData = [...userData];

        updatedUserData[index].active = !updatedUserData[index].active;
        //  
        setUserData(updatedUserData);
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add Users</p>
                <div className='flex pt-6 gap-[12rem] p-6'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Chat User</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Chat User</p>
                            </div>
                        </button>
                    </div>

                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <Toaster position="top-center reverseOrder={false}" />
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Username</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                    />
                                </div>

                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>Password</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Enter your password"
                                        value={generatedPassword}
                                        onChange={handleChange}
                                        name="password"
                                    />
                                </div>
                                <div className='pt-6 grid justify-items-end'>
                                    <button type="button" className='submit-btn-cgeneratepaswd px-6 py-2 rounded-[6px]' onClick={generatePassword}>
                                        <p className='text-[color:var(--White,#FFF)] text-[16px] not-italic font-normal leading-[24px]'>Generate Password</p>
                                    </button>
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Reenter Password</p>
                                <div className="pt-2 relative">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Reenter Password"
                                        name="password2"
                                        value={formData.password2}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'>
                                        <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
                {mailingAddressActive && userData && (
                    <div className=' '>
                        <div>
                            <div className='pt-8'>
                                <div className='bg-gray-100 py-4 w-full flex p-6 gap-2 pl-8 pr-8 '>
                                    <p className='text-[color:var(--Gray,#58585A)]   text-[18px] not-italic font-semibold leading-[normal]'>No</p>
                                    <div className=''>
                                        <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold pl-12  leading-[normal]'>Username</p>
                                    </div>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] pl-[31rem] not-italic font-semibold   leading-[normal]'>Status</p>
                                </div>
                            </div>
                            {userData.map((user, index) => (
                                <div key={index} className='py-4 w-full flex p-6 items-center pl-8 pr-8'>
                                    <div className=' '>
                                        <p className='   text-[18px] '>{index + 1}</p>
                                    </div>

                                    <div className='w-[45%] pl-16'>
                                        <p className='text-[color:var(--Gray,#58585A)]  text-[18px] not-italic font-normal leading-[normal]'>{user.username}</p>
                                    </div>


                                    <div className='pt-2 w-[130px] text-center '>
                                        <p className={` text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold leading-[normal] ${user.status === 'Active'
                                            ? 'text-[color:var(--Green,#1AB78D)] font-bold px-1.5'
                                            : 'text-[color:var(--Red,#FF6347)] font-bold'
                                            }`}>
                                            {user.status}
                                        </p>
                                    </div>
                                    <div className='pl-[4rem] flex gap-2'>
                                        <div className=' '>
                                            <button
                                                className={`w-[130px] px-4 py-2 rounded-[8px] ${user.status === 'Active' ? 'bg-green-500' : 'bg-red-500 px-6'} text-white`}
                                                onClick={() => handleStatusChange(user.id, user.status)}
                                            >
                                                {user.status === 'Active' ? 'Deactivate' : 'Activate'}
                                            </button>
                                        </div>




                                        <div className='pl-4 w-[130px]'>
                                            <button
                                                className={`px-4 py-2 rounded  bg-[#00549A] text-white`}
                                                onClick={() => handleDeleteUser(user.id)}>Delete</button>
                                        </div>
                                    </div>
                                    <div className="chat-login-container text-center my-4 text-blue-600 underline">
                                        <Link
                                            to="/speakerlogin"
                                            className="chat-login-btn btn btn-primary btn-lg"
                                        >
                                            Chat User Login (fcpi.in/speakerlogin)
                                        </Link>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default SecondUser;
