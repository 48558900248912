import React, { useState, useEffect, useRef } from 'react';
import Logo19 from "../../../assets/images/Upcoming Events (1).png";
import Logo17 from "../../../assets/images/Live Events (1).jpg";
import Logo18 from "../../../assets/images/Completed Events (1).jpg";
import Logoa from "../../../assets/images/add.png";
import Logo from "../../../assets/images/Events (1).jpg";
import Log1 from "../../../assets/images/cancel.png";
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import search from "../../../assets/images/search.png";
import { motion, useAnimation, useInView } from 'framer-motion';
import { format, utcToZonedTime } from 'date-fns-tz';


const Events = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [liveEventsVisible, setLiveEventsVisible] = useState(true);
    const [upcomingEventsVisible, setUpcomingEventsVisible] = useState(false);
    const [completedEventsVisible, setCompletedEventsVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [liveEvents, setLiveEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [completedEvents, setCompletedEvents] = useState([]);
    const [liveEventDropdownVisible, setLiveEventDropdownVisible] = useState(null);
    const [upcomingEventDropdownVisible, setUpcomingEventDropdownVisible] = useState(null);
    const [completedEventDropdownVisible, setCompletedEventDropdownVisible] = useState(null);
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const [eventsResponse, generalEventsResponse] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventslist/`),
                    axios.get(`${BASE_URL}/admins/general_events/`)
                ]);

              

                // Combine events data
                const combinedLiveEvents = [
                    ...eventsResponse.data.live_events,
                    ...generalEventsResponse.data.live_events
                ].sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

                const combinedUpcomingEvents = [
                    ...eventsResponse.data.upcoming_events,
                    ...generalEventsResponse.data.upcoming_events
                ].sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

                const combinedCompletedEvents = [
                    ...eventsResponse.data.completed_events,
                    ...generalEventsResponse.data.completed_events
                ].sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

                setLiveEvents(combinedLiveEvents);
                setUpcomingEvents(combinedUpcomingEvents);
                setCompletedEvents(combinedCompletedEvents);

            } catch (error) {
               
            }
        };

        fetchEvents();
    }, []);


    const toggleLiveEvents = () => {
        if (!liveEventsVisible) {
            setLiveEventsVisible(true);
            setUpcomingEventsVisible(false);
            setCompletedEventsVisible(false);
            setDropdownVisible(null);
        }
    };
    
    const toggleUpcomingEvents = () => {
        if (!upcomingEventsVisible) {
            setUpcomingEventsVisible(true);
            setLiveEventsVisible(false);
            setCompletedEventsVisible(false);
            setDropdownVisible(null);
        }
    };
    
    const toggleCompletedEvents = () => {
        if (!completedEventsVisible) {
            setCompletedEventsVisible(true);
            setLiveEventsVisible(false);
            setUpcomingEventsVisible(false);
            setDropdownVisible(null);
        }
    };
    

    const toggleImageVisible = (eventType, index) => {
        switch (eventType) {
            case 'live':
                setLiveEventDropdownVisible(liveEventDropdownVisible === index ? null : index);
                break;
            case 'upcoming':
                setUpcomingEventDropdownVisible(upcomingEventDropdownVisible === index ? null : index);
                break;
            case 'completed':
                setCompletedEventDropdownVisible(completedEventDropdownVisible === index ? null : index);
                break;
            default:
                break;
        }
    };

    const formatEventDate = (startDate, endDate) => {
        const parseDate = (dateString) => {
           
            const [year, month, day] = dateString.split('-');
            return { day: parseInt(day, 10), month: parseInt(month, 10), year: parseInt(year, 10) };
        };
    
        const formatDate = ({ day, month, year }) => {
            return `${day}-${month}-${year}`;
        };
    
        const start = parseDate(startDate);
        const end = parseDate(endDate);
    
        if (start.day === end.day && start.month === end.month && start.year === end.year) {
            return formatDate(start);
        } else {
            return `${formatDate(start)} - ${formatDate(end)}`;
        }
    };
    
 
    const startDate = '2024-10-25';  
    const endDate = '2024-10-25';    
    
    const formattedDate = formatEventDate(startDate, endDate);
 
    

    const formatTime = (time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        const formattedHours = parseInt(hours) > 12 ? parseInt(hours) - 12 : parseInt(hours);
        return `${formattedHours}:${minutes} ${parseInt(hours) >= 12 ? 'PM' : 'AM'}`;
    };
    const sortedEvents = [...upcomingEvents].sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] p-10 event-padding-12'>
                        <p className='text-[#222] text-start text-[32px] not-italic font-semibold leading-[normal]'>Events</p>
                        <motion.div
                            className=" "
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 4 }}
                            transition={{
                                duration: 1.5,
                                ease: "easeInOut",
                            }}
                            ref={ref}
                        >
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 pt-8 smooth">
                                <div className={`rounded-[36px] smooth ${liveEventsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D] cursor-pointer' : 'bg-gray-100 hover:bg-[#1AB78D] cursor-pointer'}`} onClick={toggleLiveEvents}>
                                    <img src={Logo17} alt="Logo" className="w-full rounded-[36px]" />
                                    <p className={`text-[#222] p-7 text-start pl-8 hover:text-white ${liveEventsVisible ? 'text-white' : ''} text-[21px] not-italic font-semibold leading-[normal] `}>Live Events</p>
                                </div>
                                <div className={`rounded-[36px] smooth ${upcomingEventsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D] cursor-pointer' : 'bg-gray-100 hover:bg-[#1AB78D] cursor-pointer'}`} onClick={toggleUpcomingEvents}>
                                    <img src={Logo19} alt="Logo" className="w-full rounded-[36px]" />
                                    <p className={`text-[#222] p-7 text-start pl-8 hover:text-white ${upcomingEventsVisible ? 'text-white' : ''} text-[21px] not-italic font-semibold leading-[normal]  `}>Upcoming Events</p>
                                </div>
                                <div className={`rounded-[36px] smooth ${completedEventsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D] cursor-pointer' : 'bg-gray-100 hover:bg-[#1AB78D] cursor-pointer'}`} onClick={toggleCompletedEvents}>
                                    <img src={Logo18} alt="Logo" className="w-full rounded-[36px]" />
                                    <p className={`text-[#222] p-7 text-start pl-8 hover:text-white ${completedEventsVisible ? 'text-white' : ''} text-[21px] not-italic font-semibold leading-[normal]  `}>Completed Events</p>
                                </div>
                            </div>
                        </motion.div>
                        {liveEventsVisible && (
                            <div className='pt-16 gap-4  '>



                                <div className='flex relative searchbox'>
                                    <div className='searchform'>
                                        <input
                                            type="text"
                                            placeholder="Search events..."
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                            className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[100%] relative"
                                        />
                                    </div>
                                    <div className='search-button'>
                                        <img src={search} alt="Search" className="search-btn" />
                                    </div>
                                </div>
                                <div className='flex'>
                                    {/* <div className=' '> */}
                                    <motion.div
                                        className="pl-2 w-[70%] max-h-[51vh] overflow-y-auto m-r-20 "
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 1,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >
                                        {liveEvents.filter(event => event.event_name.toLowerCase().includes(searchQuery.toLowerCase())).map((event, index) => (
                                            <div key={index}>
                                                <div className='flex gap-[2rem] pt-6'>
                                                    <p className={`text-[#222] w-[93%] text-[19px]  text-left not-italic font-bold leading-[30px] ${liveEventDropdownVisible === index ? 'text-green-500' : ''}`}>{event.event_name}</p>
                                                    <button
                                                        className={` rounded-[100px] smooth w-h-30`}
                                                        style={{ backgroundColor: liveEventDropdownVisible === index ? '#00549A' : '#C5E5FF' }}
                                                        onClick={() => toggleImageVisible('live', index)}
                                                    >
                                                        <img src={liveEventDropdownVisible === index ? Log1 : Logoa} alt="Close Image" className="img-45 rounded-[30px]" />
                                                    </button>
                                                </div>

                                                {liveEventDropdownVisible === index && (
                                                    <motion.div
                                                        className="  "
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 4 }}
                                                        transition={{
                                                            duration: 1,
                                                            ease: "easeInOut",
                                                        }}
                                                        ref={ref}
                                                    >
                                                        <div className=''>
                                                            <div className='flex mobflex gap-[16rem]'>
                                                                <p className='text-[#58585A] pt-2 text-start text-[16px] not-italic font-bold leading-[normal]'>{event.forum_name}</p>
                                                            </div>
                                                            <div className='flex mobflex gap-4 pt-2'>
                                                                <p className='text-[#58585A] pt-4 text-[17px] w-full not-italic font-normal leading-[normal]'>{formatEventDate(event.start_date, event.end_date)}</p>
                                                            </div>
                                                        </div>
                                                        {event.times && (
                                                            <div className='flex event-block-mob gap-4 pt-2'>
                                                                {event.days === 1 && (
                                                                    <>
                                                                        <p className='text-[#58585A] pt-4 text-[17px] not-italic font-normal leading-[normal]'>Start Time: {formatTime(event.times.start_time)}</p>
                                                                        <p className='text-[#58585A] pt-4 text-[17px]  not-italic font-normal leading-[normal]'>End Time: {formatTime(event.times.end_time)} (IST)</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                        <div className='event-bt-pt-3 smooth '>
                                                            <button className='bg-[#1AB78D]  rounded-[105px] mt-mob px-7 py-1.5'>
                                                                <Link to={`/events-detail/${event.slug}/`}>
                                                                    <p className='text-white text-[18px] not-italic font-semibold leading-[normal]'>View Event</p>
                                                                </Link>
                                                            </button>
                                                        </div>


                                                    </motion.div>
                                                )}


                                                <hr className='mt-4 w-[100%]' />
                                            </div>
                                        ))}
                                    </motion.div>
                                    {/* </div> */}
                                    <motion.div
                                        className=" "
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 1,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >
                                        <div className='mob-hide'>
                                            <img src={Logo} alt="Logo17" className="rounded-[30px]" />
                                        </div>
                                    </motion.div>
                                </div>


                            </div>
                        )}

                        {upcomingEventsVisible && (
                            <div className='pt-16 gap-4  '>



                                <div className='flex relative searchbox'>
                                    <div className='searchform'>
                                        <input
                                            type="text"
                                            placeholder="Search events..."
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                            className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[100%] relative"
                                        />
                                    </div>
                                    <div className='search-button'>
                                        <img src={search} alt="Search" className="search-btn" />
                                    </div>
                                </div>
                                <div className='flex'>
                                    {/* <div className=' '> */}
                                    <motion.div
                                        className="pl-2 w-[70%] max-h-[51vh] overflow-y-auto m-r-20 "
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 1,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >
                                        {upcomingEvents.filter(event => event.event_name.toLowerCase().includes(searchQuery.toLowerCase())).map((event, index) => (
                                            <div key={index}>
                                                <div className='flex gap-[2rem] pt-6'>
                                                    <p className={`text-[#222] w-[93%] text-[19px]  text-left not-italic font-bold leading-[30px] ${upcomingEventDropdownVisible === index ? 'text-green-500' : ''}`}>{event.event_name}</p>
                                                    <button
                                                        className={` rounded-[100px] smooth w-h-30`}
                                                        style={{ backgroundColor: upcomingEventDropdownVisible === index ? '#00549A' : '#C5E5FF' }}
                                                        onClick={() => toggleImageVisible('upcoming', index)}
                                                    >
                                                        <img src={upcomingEventDropdownVisible === index ? Log1 : Logoa} alt="Close Image" className="img-45 rounded-[30px]" />
                                                    </button>
                                                </div>

                                                {upcomingEventDropdownVisible === index && (
                                                    <motion.div
                                                        className="  "
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 4 }}
                                                        transition={{
                                                            duration: 1,
                                                            ease: "easeInOut",
                                                        }}
                                                        ref={ref}
                                                    >
                                                        <div className=''>
                                                            <div className='flex mobflex gap-[16rem]'>
                                                                <p className='text-[#58585A] pt-2 text-start text-[16px] not-italic font-bold leading-[normal]'>{event.forum_name}</p>
                                                            </div>
                                                            <div className='flex mobflex gap-4 pt-2'>
                                                                <p className='text-[#58585A] pt-4 text-[17px] w-full not-italic font-normal leading-[normal]'>{formatEventDate(event.start_date, event.end_date)}</p>
                                                            
                                                            </div>
                                                        </div>
                                                        {event.times && (
                                                            <div className='flex event-block-mob gap-4 pt-2'>
                                                                {event.days === 1 && (
                                                                    <>
                                                                        <p className='text-[#58585A] pt-4 text-[17px] not-italic font-normal leading-[normal]'>Start Time: {formatTime(event.times.start_time)}</p>
                                                                        <p className='text-[#58585A] pt-4 text-[17px]  not-italic font-normal leading-[normal]'>End Time: {formatTime(event.times.end_time)} (IST)</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                        <div className='event-bt-pt-3 smooth '>
                                                            <button className='bg-[#1AB78D]  rounded-[105px] mt-mob px-7 py-1.5'>
                                                                <Link to={`/events-detail/${event.slug}/`}>
                                                                    <p className='text-white text-[18px] not-italic font-semibold leading-[normal]'>View Event</p>
                                                                </Link>
                                                            </button>
                                                        </div>


                                                    </motion.div>
                                                )}


                                                <hr className='mt-4 w-[100%]' />
                                            </div>
                                        ))}
                                    </motion.div>
                                    {/* </div> */}
                                    <div className='mob-hide'>
                                        <img src={Logo} alt="Logo17" className="rounded-[30px]" />
                                    </div>
                                </div>


                            </div>
                        )}
                        {completedEventsVisible && (
                            <div className='pt-16 gap-4  '>



                                <div className='flex relative searchbox'>
                                    <div className='searchform'>
                                        <input
                                            type="text"
                                            placeholder="Search events..."
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                            className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[100%] relative"
                                        />
                                    </div>
                                    <div className='search-button'>
                                        <img src={search} alt="Search" className="search-btn" />
                                    </div>
                                </div>
                                <div className='flex'>
                                    {/* <div className=' '> */}
                                    <motion.div
                                        className="pl-2 w-[70%] max-h-[51vh] overflow-y-auto m-r-20 "
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 1,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >
                                        {completedEvents.filter(event => event.event_name.toLowerCase().includes(searchQuery.toLowerCase())).map((event, index) => (
                                            <div key={index}>
                                                <div className='flex gap-[2rem] pt-6'>
                                                    <p className={`text-[#222] w-[93%] text-[19px]  text-left not-italic font-bold leading-[30px] ${completedEventDropdownVisible === index ? 'text-green-500' : ''}`}>{event.event_name}</p>
                                                    <button
                                                        className={` rounded-[100px] smooth w-h-30`}
                                                        style={{ backgroundColor: completedEventDropdownVisible === index ? '#00549A' : '#C5E5FF' }}
                                                        onClick={() => toggleImageVisible('completed', index)}
                                                    >
                                                        <img src={completedEventDropdownVisible === index ? Log1 : Logoa} alt="Close Image" className="img-45 rounded-[30px]" />
                                                    </button>
                                                </div>

                                                {completedEventDropdownVisible === index && (
                                                    <motion.div
                                                        className="  "
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 4 }}
                                                        transition={{
                                                            duration: 1,
                                                            ease: "easeInOut",
                                                        }}
                                                        ref={ref}
                                                    >
                                                        <div className=''>
                                                            <div className='flex mobflex gap-[16rem]'>
                                                                <p className='text-[#58585A] pt-2 text-start text-[16px] not-italic font-bold leading-[normal]'>{event.forum_name}</p>
                                                            </div>
                                                            <div className='flex mobflex gap-4 pt-2'>
                                                                <p className='text-[#58585A] pt-4 text-[17px] w-full not-italic font-normal leading-[normal]'>{formatEventDate(event.start_date, event.end_date)}</p>
                                                            </div>
                                                        </div>
                                                        {event.times && (
                                                            <div className='flex event-block-mob gap-4 pt-2'>
                                                                {event.days === 1 && (
                                                                    <>
                                                                        <p className='text-[#58585A] pt-4 text-[17px] not-italic font-normal leading-[normal]'>Start Time: {formatTime(event.times.start_time)}</p>
                                                                        <p className='text-[#58585A] pt-4 text-[17px]  not-italic font-normal leading-[normal]'>End Time: {formatTime(event.times.end_time)} (IST)</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                        <div className='event-bt-pt-3 smooth '>
                                                            <button className='bg-[#1AB78D]  rounded-[105px] mt-mob px-7 py-1.5'>
                                                                <Link to={`/events-detail/${event.slug}/`}>
                                                                    <p className='text-white text-[18px] not-italic font-semibold leading-[normal]'>View Event</p>
                                                                </Link>
                                                            </button>
                                                        </div>


                                                    </motion.div>
                                                )}


                                                <hr className='mt-4 w-[100%]' />
                                            </div>
                                        ))}
                                    </motion.div>
                                    {/* </div> */}
                                    <div className='mob-hide'>
                                        <img src={Logo} alt="Logo17" className="rounded-[30px]" />
                                    </div>
                                </div>


                            </div>
                        )}



                    </div>
                </div>
            </div >
        </div >
    );
};

export default Events;
